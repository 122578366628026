@import "./assets/scss/theme.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

// @font-face {
//   font-family: 'HelveticaLight';
//   src: url('../src/assets/fonts/HelveticaLight.eot');
//   src: url('../src/assets/fonts/HelveticaLight.eot') format('embedded-opentype'),
//        url('../src/assets/fonts/HelveticaLight.woff2') format('woff2'),
//        url('../src/assets/fonts/HelveticaLight.woff') format('woff'),
//        url('../src/assets/fonts/HelveticaLight.ttf') format('truetype'),
//        url('../src/assets/fonts/HelveticaLight.svg#HelveticaLight') format('svg');
// }
// @font-face {
//   font-family: 'HelveticaBold';
//   src: url('../src/assets/fonts/HelveticaBold.eot');
//   src: url('../src/assets/fonts/HelveticaBold.eot') format('embedded-opentype'),
//        url('../src/assets/fonts/HelveticaBold.woff2') format('woff2'),
//        url('../src/assets/fonts/HelveticaBold.woff') format('woff'),
//        url('../src/assets/fonts/HelveticaBold.ttf') format('truetype'),
//        url('../src/assets/fonts/HelveticaBold.svg#HelveticaBold') format('svg');
// }
// @font-face {
//   font-family: 'HelveticaNormal';
//   src: url('../src/assets/fonts/HelveticaNormal.eot');
//   src: url('../src/assets/fonts/HelveticaNormal.eot') format('embedded-opentype'),
//        url('../src/assets/fonts/HelveticaNormal.woff2') format('woff2'),
//        url('../src/assets/fonts/HelveticaNormal.woff') format('woff'),
//        url('../src/assets/fonts/HelveticaNormal.ttf') format('truetype'),
//        url('../src/assets/fonts/HelveticaNormal.svg#HelveticaNormal') format('svg');
// }

@font-face {
  font-family: 'SanFranciscoReg';
  // src: url('../src/assets/fonts/SanFrancisco/pro/SF-Pro-Text-Regular.otf') format('opentype')
  src: url('../src/assets/fonts/SanFrancisco/otf/SFNSText-Medium.otf') format('opentype')
}

@font-face {
  font-family: 'SanFranciscoBold';
  src: url('../src/assets/fonts/SanFrancisco/otf/SFNSText-Bold.otf') format('opentype')
}

@font-face {
  font-family: 'SanFranciscoLight';
  src: url('../src/assets/fonts/SanFrancisco/pro/SF-Pro-Text-Light.otf') format('opentype')
}






html,
body {
  font-family: var(--san_francisco_reg);
  line-height: 1.5;
  font-weight: 500;
}

// ::-webkit-scrollbar {
//   // width:10px;
//   width: 8px;
//   height: 10px;
// }
::-webkit-scrollbar {
  // width:10px;
  width: 7px;
  height: 5px;
}


::-webkit-scrollbar-track {
  background: #cfcfcf;
}

::-webkit-scrollbar-thumb {
  background: #06345B;
  border-radius: 7px;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background: #06345B;
  border-radius: 10px;
}
:root{
  --white: #ffffff;
  // --helvetica_light: 'HelveticaLight';
  // --helvetica_bold: 'HelveticaBold';
  // --helvetica_normal: 'HelveticaNormal';
  --san_francisco_reg : 'SanFranciscoReg';
  --san_francisco_bold : 'SanFranciscoBold';
  --san_francisco_light : 'SanFranciscoLight';
  --black: #000000;
  --flex_box: flex;
  --justify_content_between: space-between;
  --flex_wrap: wrap;
}

// LMS 2024 CSS
.head-img {
  margin-right: 5px; position: relative; top: -2px;cursor: pointer;
}
.select_subject { border-radius: 5px;
  select{ background: transparent; -webkit-appearance: none; -ms-appearance: none; -moz-appearance: none; appearance: none; width: 100%; border: none; border-radius: 5px; padding: 8px 16px; background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/subject_icon.svg') no-repeat 95% 10px; cursor: pointer; color: var(--white); font-family: var(--san_francisco_reg);}
}
.grid { margin: 30px 15px 30px 280px;}
.video_box .plyr--video{ border-radius: 5px;}
.footer_menu_dropdown{ border-radius: 5px; background: linear-gradient(0deg, rgba(29, 30, 31, 0.83) 0%, rgba(29, 30, 31, 0.83) 100%), #FFF; padding: 10px !important; width: 226px; position: absolute !important; bottom: 105px !important;}
.footer_menu_dropdown ul{ list-style-type: none; margin: 0px 0px; padding: 0px 0px;}
.footer_menu_dropdown ul li{ font-family: var(--san_francisco_reg); font-size: 14px; line-height: 136.523%; color: var(--white); padding: 7px 10px;}
.footer_menu_dropdown ul li a{ color: var(--white); display: block;}
.footer_menu_dropdown ul li:hover, .footer_menu_dropdown ul li a:hover{ color: #e63a46;}
.custom_select_box{ 
  .ng-select-container{ border-radius: 10px; border: 1px solid rgba(0, 0, 0, 0.15); background: #FFF; padding: 15px 16px !important; height: auto !important; margin-top: 30px; font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{ font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{ background: rgba(0, 0, 0, 0.04);}
  .ng-select .ng-select-container .ng-value-container .ng-input>input{ padding: 7px 16px !important;}
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow{ transform: rotate(-45deg);top: -1px; transform: rotate(-182deg);}
  .ng-select .ng-arrow-wrapper .ng-arrow{ background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Gameplan-Status/down_arrow.svg') no-repeat 0px 0px; border: none; width: 13px; height: 11px; position: relative; top: 4px;}
}

.exam_status_select_box{ 
  .ng-select-container{ border-radius: 10px; border: 1px solid rgba(0, 0, 0, 0.15); background: rgba(255, 255, 255, 0.50); padding: 15px 16px !important; height: auto !important; margin-top: 50px; font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-select.ng-select-opened>.ng-select-container{ background: rgba(255, 255, 255, 0.50);}
  .ng-dropdown-panel{ background: rgba(255, 255, 255, 0) !important;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{ font-family: var(--san_francisco_reg); font-size: 14px; color: #474747; background: rgba(255, 255, 255, 0.50);}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{ background: rgb(248 248 248 / 30%);}
  .ng-select .ng-select-container .ng-value-container .ng-input>input{ padding: 7px 16px !important;}
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow{ transform: rotate(-45deg);top: -1px; transform: rotate(-182deg);}
  .ng-select .ng-arrow-wrapper .ng-arrow{ background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Gameplan-Status/down_arrow.svg') no-repeat 0px 0px; border: none; width: 13px; height: 11px; position: relative; top: 4px;}
}
.custom_modal .modal-dialog{ background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Gameplan-Status/dialogBackground.svg') no-repeat 0px 0px / cover; box-shadow: none; padding: 50px 80px; height: 450px;}
.custom_modal .modal-dialog .modal-content{ background: transparent; box-shadow: none; }
.close_btn{ opacity: 1; right: -48px !important; top: 15px !important;}

.gameplan_error_modal{ background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Gameplan-Status/gameplan_error_background.png') no-repeat 0px 0px / cover; box-shadow: none; height: auto; bottom: 0px; top: auto; width: 100vw;}
.gameplan_error_modal .modal-dialog .modal-content{ background: transparent; box-shadow: none;  position: relative !important; width: 100%; padding: 40px 0px 0px 0px}
.error_lottie{ margin: auto; z-index: 1000; position: relative; height: 150px; width: 150px;}
.gameplan_error_text{ color: #000; font-family: var(--san_francisco_reg); font-size: 20px; font-weight: 400; line-height: 45px; letter-spacing: -0.4px;}
.full_width{ min-width: 1170px;}
.error_lottie2{ margin: auto; z-index: 1000; position: relative; height: 82px; width: 77px;}
.gameplan_error_text_bold{ color: #000; font-family: var(--san_francisco_bold); font-size: 20px; font-weight: 400; line-height: 32px; letter-spacing: -0.4px;}
.edit_btn_new{ border-radius: 8px !important; background: #06345B; border: none;padding: 10px 16px; color: var(--white); margin: 20px 0px 0px; font-family: var(--san_francisco_reg); font-size: 16px; line-height: 24px;}
.pagination_box { border-radius: 15px; margin-bottom: 20px; padding: 19px; display: flex; align-items: center; font-family: var(--san_francisco_reg); color: #000000; font-size: 18px; background: rgba(255, 255, 255, 0.25) !important; backdrop-filter: blur(14px); border: 1px solid #ddddde;}
.error_close_btn{ opacity: 1; right: 48px !important; top: 15px !important;}

.f2f_tab .nav li a.active{ border-radius: 15px 15px 0px 0px; font-size: 16px; font-family: var(--san_francisco_bold); background-color: #f5f1e5 !important;}
.f2f_tab .tab-content{ margin: 0px 0px !important; border-radius: 0px 20px 20px 20px !important;}
.f2f_tab .nav li a{ padding: 1rem 1.5rem !important; background-color: rgba(154, 200, 253, 0.2392156863); font-size: 16px; font-family: var(--san_francisco_bold); margin-right: 20px; border-radius: 15px 15px 0px 0px; color: #212529; text-transform: uppercase;}
input:-internal-autofill-selected { color: #575757;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus { color: #575757;  -webkit-text-fill-color: #575757;}

.rules_collapse_box{ border-radius: 10px; background: rgba(211, 210, 210, 0.10); box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10); margin-top: 20px; border: 1px solid #edecec;}
.rules_collapse_box .accordion .card .card-header{ color: #000000; font-family: var(--san_francisco_reg);}
.rules_collapse_box .accordion .card .card-header a h5{ font-size: 16px;}
.rules_collapse_box .mdb-accordion-indicator{ top: 22px !important;}
.rules_collapse_box .md-accordion .card:first-of-type{ background: rgba(255, 255, 255, 0.25) !important;}
.mentoring_select_box{ 
  .ng-select-container{ border-radius: 5px; border: 1px solid rgba(0, 0, 0, 0.15); background: #FFF; padding: 15px 16px !important; height: auto !important; margin-top: 0px; margin-bottom: 16px; font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{ font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{ background: rgba(0, 0, 0, 0.04);}
  .ng-select .ng-select-container .ng-value-container .ng-input>input{ padding: 7px 7px !important;}
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow{ transform: rotate(-45deg);top: -1px; transform: rotate(-182deg);}
  .ng-select .ng-select-container .ng-value-container{ padding-left: 0px !important;}
  .ng-select .ng-arrow-wrapper .ng-arrow{ background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Gameplan-Status/down_arrow.svg') no-repeat 0px 0px; border: none; width: 13px; height: 11px; position: relative; top: 4px;}
}

.session_select_box{ 
  .ng-select-container{ border-radius: 5px; border: 1px solid rgba(0, 0, 0, 0.15); background: #FFF; padding: 8px 10px !important; height: auto !important; margin-top: 0px; margin-bottom: 0px; font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{ font-family: var(--san_francisco_reg); font-size: 14px; color: #000000;}
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{ background: rgba(0, 0, 0, 0.04);}
  .ng-select .ng-select-container .ng-value-container .ng-input>input{ padding: 4px 0px !important;}
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow{ transform: rotate(-45deg);top: -1px; transform: rotate(-182deg);}
  .ng-select .ng-select-container .ng-value-container{ padding-left: 0px !important;}
  .ng-select .ng-arrow-wrapper .ng-arrow{ background: url('https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Gameplan-Status/down_arrow.svg') no-repeat 0px 0px; border: none; width: 13px; height: 11px; position: relative; top: 4px;}
}

.documents_box::-webkit-scrollbar {
  width: 7px;
  height: 0.5rem;
}

.documents_box::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.documents_box::-webkit-scrollbar-thumb {
  background: #06345B;
  border-radius: 10px;
}

.md-form textarea.md-textarea {
  overflow-y: hidden !important;
}

.text-transform-none {
  text-transform: none !important;
}

.transform-none {
  text-transform: none !important;
}

.cursor-disable {
  cursor: no-drop !important;

  input {
    cursor: no-drop !important;
  }
}

.overflow-y {
  overflow-y: auto !important;
}

.md-tabs {
  padding: 0px !important;
  background: rgba(255, 255, 255, 0.6) !important;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px) !important;
}

.md-tabs .nav-link {
  color: #5f6262 !important;
  padding: 15px 0px;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.md-tabs .nav-item a {
  text-align: center !important;
}

.md-tabs .nav-link.active {
  background-color: #06345B !important;
  color: #fff !important;
}

.pac-container {
  z-index: 10000 !important;
}

.card.tab-content.tab-fof {
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: none !important;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px) !important;
  border-bottom: 1px solid #fff;
}

.card-wrapper {
  height: 695px !important;
}

.hover:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1e87dd;
}

.navbar {
  box-shadow: 10px 2px 5px 0 rgba(0, 0, 0, 0.16), 10px 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  font-weight: 300;
}

ul.stepper li a .circle {
  border-radius: 10% !important;
}

// MCQ Practice font Override
.question_header,
.correct_answer_box {
  .middle_section {
    p {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;

      h1,
      .h1 {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }

      h2,
      .h2 {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }

      h3,
      .h3 {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }

      h4,
      .h4 {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }

      h5,
      .h5 {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }

      h6,
      .h6 {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }

      span,
      .span {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #555555 !important;
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #555555 !important;

    h1,
    .h1 {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }

    h2,
    .h2 {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }

    h3,
    .h3 {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }

    h4,
    .h4 {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }

    h5,
    .h5 {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }

    h6,
    .h6 {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }

    span,
    .span {
      margin: 0;
      padding: 0;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #555555 !important;
    }
  }
}

// .modal-footer{ justify-content: flex-start !important;}
.height-100vh {
  min-height: 100vh !important;
}

.popover {
  width: 200px !important;
}

nav {
  z-index: 20 !important;
}

// -- breadcrumb
.bclink {
  font-weight: 400 !important;
}

.bcactive {
  font-weight: 400 !important;
  cursor: default;
}

.navbar .breadcrumb .breadcrumb-item.active,
.navbar .breadcrumb .breadcrumb-item:before {
  color: rgb(26, 26, 26) !important;
}

.navbar .breadcrumb {
  margin: 0;
  padding: 0 0 0 1rem !important;
  background-color: inherit;
  font-size: 15px;
  font-weight: 300;
}

//  breadcrumb --
:focus {
  outline: none;
}

.classic-tabs .nav li a:not(.active) {
  margin-bottom: 3px !important;
  font-weight: 400 !important;
}

.classic-tabs .nav li a.active {
  border-bottom: 5px solid !important;
  color: #178fff !important;
  font-weight: 500;
  background: #fff;
}

.card.loan-box.tab-content {
  border: 1px solid rgb(143 132 132 / 13%);
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px;
  padding: 1.5rem 1rem 1rem !important;
}

button {
  border-radius: 7px !important;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.button-collapse {
  display: block !important;
}

.calculator {
  overflow: auto;
  height: 300px;
  min-height: 100%;
  width: 510px !important;
  position: relative;
  border-radius: 0;
  transform: scale(0.999);
}

.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: #fff;
}

.ph-item,
.ph-item *,
.ph-item ::after,
.ph-item ::before {
  box-sizing: border-box;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
  animation: phAnimation 0.8s linear infinite;
  background: -webkit-linear-gradient(to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-picture {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
}

@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

p {
  margin-bottom: 0rem;
}

.tabs-3 {
  margin: 0px !important;
}

.md-tabs .nav-item {
  text-align: left !important;
}

.tab-content {
  padding: 1.5rem 1.5rem 1.5rem !important;
}

.indigo2 {
  background-color: #5862e7 !important;
}

// .tab_icon {
//   width: 20px !important;
// }

.subject-block circle-progress svg text tspan:nth-child(1) {
  font-weight: 600;
  font-family: "Lato", sans-serif !important;
}

.subject-block circle-progress svg text tspan:nth-child(2) {
  font-size: 12px;
  font-weight: 600;
  font-family: "Lato", sans-serif !important;
}

.subject-block circle-progress svg text tspan:nth-child(3) {
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
}

.custom-modal-header {
  background: #007bffc7;
}

.custom-modal-header .modal-title {
  color: #fff !important;
}

.custom-modal-header .close {
  color: #fff;
  opacity: 1;
}

.custom-modal-footer {
  background: #dee2e6;
  border-top: 1px solid #cdcfd2;
}

//--- ###### Sidebar Navbar Begin ###### ---//
.launch-li button {
  // background-color: #7b76e4 !important;
  background-color: #00558c !important;
}

.launch-li mdb-popover-container .popover-body {
  // background-color: #7b76e454;
  background-color: #00558c54;
}

.launch-li mdb-popover-container.popover.popover-top::before,
.launch-li mdb-popover-container.popover.popover-top::after {
  margin-left: -70px;
  // border-top-color: #7b76e4b3;
  border-top-color: #00558cb3;
}

.aud-li button,
.aud-gamePlan-active .preparation_box_heading,
.custom-switch-planner-aud .slider::before {
  background-color: #84A59D !important;
}

.aud-li mdb-popover-container .popover-body,
.aud-gamePlan-active .list,
.custom-switch-planner-aud .slider {
  background: #84A59D54 !important;
}

.aud-li mdb-popover-container.popover.popover-bottom::before,
.aud-li mdb-popover-container.popover.popover-bottom::after {
  border-bottom-color: #84A59Db3;
}

.aud-li mdb-popover-container.popover.popover-right::before,
.aud-li mdb-popover-container.popover.popover-right::after {
  border-right-color: #84A59Db3;
}

.aud-li mdb-popover-container.popover.popover-top::before,
.aud-li mdb-popover-container.popover.popover-top::after {
  border-top-color: #84A59Db3;
}

.aud-li mdb-popover-container.popover.popover-left::before,
.aud-li mdb-popover-container.popover.popover-left::after {
  border-left-color: #84A59Db3;
}


.bec-li button,
.bec-gamePlan-active .preparation_box_heading,
.custom-switch-planner-bec .slider::before {
  background-color: #F6BD60 !important;
}

.bec-li mdb-popover-container .popover-body,
.bec-gamePlan-active .list,
.custom-switch-planner-bec .slider {
  background: #F6BD6054 !important;
}

.bec-li mdb-popover-container.popover.popover-bottom::before,
.bec-li mdb-popover-container.popover.popover-bottom::after {
  border-bottom-color: #F6BD60b3;
}

.bec-li mdb-popover-container.popover.popover-right::before,
.bec-li mdb-popover-container.popover.popover-right::after {
  border-right-color: #F6BD60b3;
}

.bec-li mdb-popover-container.popover.popover-top::before,
.bec-li mdb-popover-container.popover.popover-top::after {
  border-top-color: #F6BD60b3;
}

.bec-li mdb-popover-container.popover.popover-left::before,
.bec-li mdb-popover-container.popover.popover-left::after {
  border-left-color: #fac934b3;
}


.far-li button,
.far-gamePlan-active .preparation_box_heading,
.custom-switch-planner-far .slider::before {
  background-color: #457B9D !important;
}

.far-li mdb-popover-container .popover-body,
.far-gamePlan-active .list,
.custom-switch-planner-far .slider {
  background: #457B9D54 !important;
}

.far-li mdb-popover-container.popover.popover-bottom::before,
.far-li mdb-popover-container.popover.popover-bottom::after {
  border-bottom-color: #457B9Db3;
}

.far-li mdb-popover-container.popover.popover-right::before,
.far-li mdb-popover-container.popover.popover-right::after {
  border-right-color: #457B9Db3;
}

.far-li mdb-popover-container.popover.popover-top::before,
.far-li mdb-popover-container.popover.popover-top::after {
  border-top-color: #457B9Db3;
}

.far-li mdb-popover-container.popover.popover-left::before,
.far-li mdb-popover-container.popover.popover-left::after {
  border-left-color: #457B9Db3;
}
.reg-li button,
.reg-gamePlan-active .preparation_box_heading,
.custom-switch-planner-reg .slider::before {
  background-color: #E63946 !important;
}

.reg-li mdb-popover-container .popover-body,
.reg-gamePlan-active .list,
.custom-switch-planner-reg .slider {
  background: #E6394654 !important;
}

.reg-li mdb-popover-container.popover.popover-bottom::before,
.reg-li mdb-popover-container.popover.popover-bottom::after {
  border-bottom-color: #E63946b3;
}

.reg-li mdb-popover-container.popover.popover-right::after,
.reg-li mdb-popover-container.popover.popover-right::before {
  border-right-color: #E63946b3;
}

.reg-li mdb-popover-container.popover.popover-top::after,
.reg-li mdb-popover-container.popover.popover-top::before {
  border-top-color: #E63946b3;
  margin-left: 50px;
}

.reg-li mdb-popover-container.popover.popover-left::after,
.reg-li mdb-popover-container.popover.popover-left::before {
  border-left-color: #d13d3ab3;
}

.bar-li button,
.bar-gamePlan-active .preparation_box_heading,
.custom-switch-planner-bar .slider::before {
  background-color: #F6BD60 !important;
}

.bar-li mdb-popover-container .popover-body,
.bar-gamePlan-active .list,
.custom-switch-planner-bar .slider {
  background: #F6BD6054 !important;
}

.bar-li mdb-popover-container.popover.popover-bottom::before,
.bar-li mdb-popover-container.popover.popover-bottom::after {
  border-bottom-color: #F6BD60b3;
}

.bar-li mdb-popover-container.popover.popover-right::before,
.bar-li mdb-popover-container.popover.popover-right::after {
  border-right-color: #F6BD60b3;
}

.bar-li mdb-popover-container.popover.popover-top::before,
.bar-li mdb-popover-container.popover.popover-top::after {
  border-top-color: #F6BD60b3;
}

.bar-li mdb-popover-container.popover.popover-left::before,
.bar-li mdb-popover-container.popover.popover-left::after {
  border-left-color: #fac934b3;
}


.aud-gamePlan-active label:after,
.aud-gamePlan-active .form-check-input[type="radio"]:checked+label:after {
  background-color: #0c4c1b !important;
  border: 2px solid #0c4c1b;
}

.bec-gamePlan-active label:after,
.bec-gamePlan-active .form-check-input[type="radio"]:checked+label:after {
  background-color: #715707 !important;
  border: 2px solid #715707;
}

.far-gamePlan-active label:after,
.far-gamePlan-active .form-check-input[type="radio"]:checked+label:after {
  background-color: #145b82 !important;
  border: 2px solid #145b82;
}

.reg-gamePlan-active label:after,
.reg-gamePlan-active .form-check-input[type="radio"]:checked+label:after {
  background-color: #5d1a1c !important;
  border: 2px solid #5d1a1c;
}

.bar-gamePlan-active label:after,
.bar-gamePlan-active .form-check-input[type="radio"]:checked+label:after {
  background-color: #715707 !important;
  border: 2px solid #715707;
}

//--- ###### Sidebar Navbar Ends ###### ---//

//--- ###### Pagination Navbar Begins ###### ---//
.custom-pagination ul.ngx-pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0px !important;
}

.custom-pagination ul.ngx-pagination li.current {
  background-color: #d74c2a;
}

.custom-pagination ul.ngx-pagination li.current,
.custom-pagination ul.ngx-pagination li {
  padding: 0.4rem 0.3rem !important;
}

//--- ###### Pagination Navbar Ends ###### ---//

mdb-tabset.custom-classic-tab ul li a {
  padding: 15px 24px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.plyr__control--overlaid {
  background: #1976d2 !important;
}

@keyframes PlaceHolderShining {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.skeleton {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: PlaceHolderShining;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 250px;
  height: 100%;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  color: #555555;
}

/* Mamatha Styles */
mdb-accordion-item-head div a h5 span table.reports_table {
  border-top: 0px !important;
  border: 0px;
}

mdb-accordion-item-body div div.card-body table.reports_table,
mdb-accordion-item-body div div.card-body table.reports_table tbody tr td {
  border-bottom: 0px !important;
}

.middle_section>table {
  margin-top: 15px;
  margin-bottom: 15px;
}

.middle_section>table>tbody>tr>td {
  padding: 5px 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}

.body-block {
  // margin-left: 250px;
  margin-left: 200px;
  transition: all 1s;
}

.body-block-lg {
  margin-left: 93px;
  transition: all 1s;
}

// .fnav{
//   // right: 265px !important;
//   right: 210px !important;
//   position: absolute !important;
// }
.fnav-normal {
  right: 210px !important;
  position: absolute !important;
  transition: all 1s;
}

.fnav-collapse {
  right: 110px !important;
  position: absolute !important;
  transition: all 1s;
}
.city_dropdown .ng-select.custom, .ng-select.custom .ng-select-container{ font-size: 14px !important;}

@media only screen and (max-width: 996px) {
  h4.form_details_heading {
    font-size: 1rem;
  }

  // nav.navbar.custom-navbar{padding: 0.2rem 1rem !important;}
  // nav.navbar.custom-navbar{padding: 0rem !important;}

  // .body-block{
  //   margin-left: 0px;
  // }
  // .fnav-normal,.fnav-collapse{right: 0 !important;position: absolute !important;}
}

@media only screen and (max-width: 1024px) {
  ul.stepper.horizontal .step-title {
    max-width: 3rem !important;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .pagination-previous {
    display: none !important;
  }

  .pagination-next {
    display: none !important;
  }

  .custom-pagination ul.ngx-pagination li.current,
  .custom-pagination ul.ngx-pagination li {
    padding: 0rem 0rem !important;
    font-size: 0.85rem;
  }

  .custom-pagination ul.ngx-pagination li.current {
    background-color: #d74c2a;
    padding: 0.1rem 0.3rem !important;
    font-size: 0.85rem;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    padding: 0.1875rem 0.425rem !important;
  }

  .middle_section>table {
    display: block;
    width: 100%;
    overflow-x: auto;
    border: none;
  }

  .tab-content {
    padding: 1rem 1rem 1rem !important;
  }

  .body-block {
    margin-left: 0px !important;
  }

  .fnav-normal,
  .fnav-collapse {
    right: 0 !important;
    position: absolute !important;
  }

  nav.navbar.custom-navbar {
    padding: 0rem !important;
  }

  .card-wrapper {
    height: 685px !important;
  }

  .swal2-show {
    width: 90% !important;
  }

  .classic-tabs .nav li a {
    font-size: 10px !important;
    padding: 10px 12px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .pagination-previous {
    display: none !important;
  }

  .pagination-next {
    display: none !important;
  }

  .custom-pagination ul.ngx-pagination li.current,
  .custom-pagination ul.ngx-pagination li {
    padding: 0rem 0rem !important;
  }

  .custom-pagination ul.ngx-pagination li.current {
    background-color: #d74c2a;
    padding: 0.4rem 0.6rem !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    padding: 0.1875rem 0.425rem !important;
  }

  .body-block {
    margin-left: 0px !important;
  }

  .fnav-normal,
  .fnav-collapse {
    right: 0 !important;
    position: absolute !important;
  }

  nav.navbar.custom-navbar {
    padding: 0rem !important;
  }

  .classic-tabs .nav li a {
    font-size: 12px !important;
    padding: 10px 12px !important;
  }
}

.mydp>.md-form {
  margin: 0px 0px !important;
}

.mdb-select-value {
  padding: 0.4rem 0 0.4rem !important;
}

.purple {
  background: #6268e6 !important;
}

.md-tabs .nav-item.disabled .nav-link {
  color: #fff !important;
}

.mdb-select-value {
  background-color: #fff !important;
}

.col-md-9>.tab-content {
  padding: 0px !important;
  box-shadow: none !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container,
ng-select.ng-invalid.ng-touched .ng-select-container:hover,
input.form-control.validate-error.ng-invalid.ng-touched {
  border: 2px solid #f44336 !important;
  box-shadow: none !important;
  height: 38px;
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

////--- ### --- Custom LMS Tabs Begins --- ###---////

// .custom-lms-tabs{
//   border-top: 1px solid #1976d2!important;
//   border-bottom: 1px solid #1976d2!important;
// }

ul.custom-lms-tabs.nav li a:not(.active),
ul.custom-lms-tabs.nav li.active a.active {
  margin-bottom: 0px !important;
  // color: #fff !important;
}

ul.custom-lms-tabs li.active a.active {
  box-shadow: 1px 2px 5px #cecece;
  background-color: #fff;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #b6d866f0 !important;
}

ul.custom-lms-tabs li:nth-child(1).active a.active {
  color: #405a03f0 !important;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #b6d866f0 !important;
}

ul.custom-lms-tabs li:nth-child(2).active a.active {
  color: #987409 !important;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #e6c665 !important;
}

ul.custom-lms-tabs li:nth-child(3).active a.active {
  color: #095f80 !important;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #6eccef !important;
}

ul.custom-lms-tabs li:nth-child(4).active a.active {
  color: #7d090d !important;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #f19094 !important;
}

ul.custom-lms-tabs li:nth-child(5).active a.active {
  color: #400e92 !important;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #9184e3 !important;
}

// studyplanner tabs
.new-study-tab.tab-content.card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.new-study-tab .card {
  border: none;
  border-radius: 0px 8px 8px 8px !important;
  padding: 0;
  margin: 0;
}

.new-study-tab .card .tab-content {
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
}

ul.new-study-tab li {
  margin: 0 6px;
  border-radius: 10px 10px 0 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.new-study-tab .nav li a:not(.active) {
  margin-bottom: 0 !important;
  padding: 20px !important;
}

.new-study-tab .nav li a.active {
  padding: 14px 20px !important;
  height: 50px;
}

.new-study-tab .nav li:nth-child(1) a {
  background: rgb(66 133 244 / 0.22);
  color: rgb(0 0 0 / 50%);
  border-radius: 8px 8px 0 0;
  padding: 14px 20px !important;
  height: 50px;
}

.new-study-tab .nav li:nth-child(2) a {
  background: rgba(185, 204, 130, 0.22);
  color: rgb(0 0 0 / 50%);
  border-radius: 8px 8px 0 0;
  padding: 14px 20px !important;
  height: 50px;
}

.new-study-tab .nav li:nth-child(3) a {
  background: rgba(232, 203, 124, 0.35);
  color: rgb(0 0 0 / 50%);
  border-radius: 8px 8px 0 0;
  padding: 14px 20px !important;
  height: 50px;
}

.new-study-tab .nav li:nth-child(4) a {
  background: rgba(158, 205, 240, 0.35);
  color: rgb(0 0 0 / 50%);
  border-radius: 8px 8px 0 0;
  padding: 14px 20px !important;
  height: 50px;
}

.new-study-tab .nav li:nth-child(5) a {
  background: rgba(224, 139, 138, 0.35);
  color: rgb(0 0 0 / 50%);
  border-radius: 8px 8px 0 0;
  padding: 14px 20px !important;
  height: 50px;
}

ul.new-study-tab li:nth-child(1).active a.active {
  color: #06345B;
  background: #fff;
  border-left: 5px solid #06345B !important;
  border-bottom: none !important;
}

ul.new-study-tab li:nth-child(2).active a.active {
  color: #b9cc82 !important;
  background: #fff;
  border-left: 5px solid #b9cc82 !important;
  border-bottom: none !important;
}

ul.new-study-tab li:nth-child(3).active a.active {
  color: #e8cb7c !important;
  background: #fff;
  border-left: 5px solid #e8cb7c !important;
  border-bottom: none !important;
}

ul.new-study-tab li:nth-child(4).active a.active {
  color: #9ecdf0 !important;
  background: #fff;
  border-left: 5px solid #9ecdf0 !important;
  border-bottom: none !important;
}

ul.new-study-tab li:nth-child(5).active a.active {
  color: #e08b8a !important;
  background: #fff;
  border-left: 5px solid #e08b8a !important;
  border-bottom: none !important;
}

// sub tracker

// .cma_track .col-md-3 {padding: 0px!important;}

// .cma_track .col-md-9 > .tab-content{position:relative; padding: 15px!important;box-shadow:rgb(99 99 99 / 20%) 0px 2px 8px 0px!important;}

// .cma_track{border-radius: 0;}

ul.cma_track li {
  padding: 0px 8px;
  height: 64px;
}

ul.cma_track li span {
  font-size: 10px;
  position: absolute;
  left: 22%;
  top: 60%;
  line-height: 1.2;
}

ul.cma_track li a {
  background: transparent !important;
  color: #8d8d8d !important;
  font-size: 14px;
}

.cma_track li a:not(.active) {
  text-align: left;
  padding: 25px 12px !important;
  height: auto;
  border-left: 3px solid #dadada;
  border-radius: 0;
}

.cma_track li a.active {
  color: #000 !important;
  background: transparent !important;
  box-shadow: none;
  border-radius: 0 !important;
  text-align: left;
  text-transform: capitalize;
  border-bottom: none !important;
  height: auto;
  padding: 25px 12px !important;
}

.cma_track.track-aud li a.active {
  border-left: 3px solid #b9cc82;
}

.cma_track.track-bec li a.active {
  border-left: 3px solid #e8cb7c;
}

.cma_track.track-far li a.active {
  border-left: 3px solid #9ecdf0;
}

.cma_track.track-reg li a.active {
  border-left: 3px solid #e08b8a;
}

.cma_track .nav-link.active:hover {
  box-shadow: none;
}

// ul.custom-study-tabs li:nth-child(1).active a.active {
//   color: #1876d2 !important;
//   text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
//   border-bottom: none !important;
// }

// ul.custom-study-tabs li:nth-child(2).active a.active {
//   color: #405a03f0 !important;
//   text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
//   border-bottom: none !important;
// }

// ul.custom-study-tabs li:nth-child(3).active a.active {
//   color: #987409 !important;
//   text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
//   border-bottom: 5px solid #e6c665 !important;
// }

// ul.custom-study-tabs li:nth-child(4).active a.active {
//   color: #095f80 !important;
//   text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
//   border-bottom: 5px solid #6eccef !important;
// }

// ul.custom-study-tabs li:nth-child(5).active a.active {
//   color: #7d090d !important;
//   text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
//   border-bottom: 5px solid #f19094 !important;
// }

////--- ### --- Custom LMS Tabs Ends --- ###---////
////--- ### --- Custom LMS Tabs-New Begins --- ###---//// background-color: #fff !important;

ul.nav.classic-tabs.custom-lms-tabs-new,
ul.nav.classic-tabs.custom-feed-tabs-new {
  background-color: #fff !important;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
}

ul.custom-feed-tabs-new.nav li a:not(.active),
ul.custom-feed-tabs-new.nav li.active a.active {
  font-size: 14px !important;
  color: #2c2c2c;
  line-height: 45px;
  cursor: pointer;
  padding: 10px 15px !important;
  position: relative;
  text-transform: uppercase;
  font-weight: 600 !important;
}

ul.custom-lms-tabs-new.nav li a:not(.active),
ul.custom-lms-tabs-new.nav li.active a.active {
  font-size: 17px !important;
  color: #2c2c2c;
  line-height: 45px;
  cursor: pointer;
  padding: 10px 60px !important;
  position: relative;
  text-transform: uppercase;
  font-weight: 600 !important;
}

ul.custom-lms-tabs-new li.active a.active,
ul.custom-feed-tabs-new li.active a.active {
  // box-shadow: 1px 2px 5px #cecece;
  background-color: #fff;
  color: #00558c !important;
  font-weight: 600 !important;
  text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff;
  border-bottom: 5px solid #00558c !important;
}

////--- #### --- Custom LMS Tabs-New Ends --- ####---////

.ng-select.custom,
.ng-select.custom .ng-select-container {
  font-size: 12px;
  // width: 320px !important;
}

.ng-select.custom-mobile,
.ng-select.custom-mobile .ng-select-container {
  font-size: 12px !important;
}

.mydp-date {
  margin: 0px !important;
}

// .form-control{margin:0px !important;}
// .md-form .form-control{margin:0px !important;}
.custom-date-picker-input div.mydp div.md-form input.form-control {
  background-color: #fff;
  font-size: 12px !important;
  height: 36px;
  border: 1px solid #ccc;
}

.custom-date-picker-input.custom-width div.mydp div.md-form input.form-control {
  width: 190px;
}

.custom-width-score {
  font-size: 12px !important;
}

.md-form.md-outline.gamePlan-Subject-input input.form-control {
  border: 1px solid #ccc;
  height: 36px;
  padding: 12px;
}

mdb-date-picker.form-control.input-aud,
mdb-date-picker.form-control.input-bec {
  border: none;
  margin: 0;
  padding: 0 !important;
}

mdb-date-picker.form-control.input-aud input.form-control,
mdb-date-picker.form-control.input-bec input.form-control {
  background: #ffff;
}

.md-form.md-outline.gamePlan-Subject-input label,
.md-form.md-outline.gamePlan-Subject-input input.form-control {
  font-size: 12px !important;
}

.md-form.md-outline.gamePlan-Subject-input input.form-control.validate-error.ng-invalid.ng-touched {
  border: 1px solid #f44336 !important;
}

// ################# //
.ebox_table td {
  border-top: 1px solid #dee2e6 !important;
}

.ebox_table thead tr th {
  font-weight: 500;
}

.link {
  cursor: pointer;
}

.link:hover {
  cursor: pointer;
  color: #1139bb;
  text-shadow: 0px 0px 15px #c7c7c7;
  transition: all 300ms;
}

mdb-badge.ebox-badge-date span.ebox-badge-date,
mdb-badge.ebox-badge-time span.ebox-badge-time,
mdb-badge.ebox-badge-ticket span.ebox-badge-ticket,
mdb-badge.ebox-badge-solved-modal span.ebox-badge-solved-modal,
mdb-badge.ebox-badge-ticket-modal span.ebox-badge-ticket-modal {
  background-color: transparent !important;
  border: none !important;
}

.ebox-badge-date {
  background-color: #3f51b57a !important;
  color: #0e1e77 !important;
  // padding: 0.3rem !important;
}

.ebox-badge-time {
  background-color: #0097a782 !important;
  color: #15585f !important;
  // padding: 0.3rem !important;
}

.ebox-badge-ticket-modal {
  border: 1px solid #856404;
  background-color: #fff3cd;
  color: #856404 !important;
  // padding: 0.3rem !important;
}

.ebox-badge-ticket {
  background-color: #880e4f6b !important;
  color: #5a0935 !important;
  // padding: 0.3rem !important;
}

table.table.ebox_table tbody tr td mdb-badge,
span mdb-badge.ebox-badge {
  line-height: 10px;
  font-size: 12px !important;
  text-align: left;
  font-weight: 600;
  padding: 0.3rem !important;
  border-radius: 0.125rem !important;
}

.gameplan_stepper ul.stepper .step-title {
  width: 190px;
  padding: 19px 0rem 0 0rem !important;
  font-size: 1rem;
  text-align: center;
  color: #212529;
}

.gameplan_stepper .stepper {
  margin-top: -20px !important;
}

.gameplan_stepper ul.stepper.horizontal .step .step-title:before {
  top: 17% !important;
  left: 41% !important;
}

.gameplan_stepper ul.stepper .step.active .step-title {
  font-weight: 500;
}

.gameplan_stepper ul.stepper .step-title:hover {
  background-color: transparent !important;
  border-bottom: 4px solid #5862e7;
}

.gameplan_stepper ul.stepper.horizontal .step-new-content {
  height: 1000px;
  padding: 0px 0px 60px 0px !important;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #b6b6b9;
}

.gameplan_stepper ul.stepper .step.active .step-title {
  font-weight: 600 !important;
  border-bottom: 4px solid #5862e7;
  color: #212529;
}

.gameplan_stepper ul.stepper>.step:not(:last-of-type):after {
  display: none;
}

.gameplan_stepper ul.stepper.horizontal:before {
  content: "";
  background-color: #fff !important;
  width: 100% !important;
  min-height: 5.25rem;
  position: absolute;
  left: 0px !important;
  right: 0 !important;
  border-top-left-radius: 0px !important;
  margin-left: 0% !important;
  margin-right: 0% !important;
  border-top-right-radius: 0px;
  border: 1px solid #b6b6b9;
  border-left: 0px;
  border-right: 0px;
}

.gameplan_stepper .card-body ul.stepper.horizontal .step.active .step-title:before {
  background-color: #5862e7 !important;
}

.gameplan_stepper ul.stepper.horizontal {
  padding-top: 0px !important;
}

.select_field .mdb-select-value {
  border-radius: 4px !important;
  border: 1px solid #ccc !important;
}

.select_field .mdb-select-placeholder {
  padding-left: 10px !important;
}

.forum-main {
  main.grid {
    margin: 1rem 0rem;
  }

  main.question-card-block .question-card .question_box {
    .question_box_body {
      padding: 10px 15px;
    }
  }
}

.forum-post-name-action {
  mdb-popover-container {
    top: -20px;
    left: 48px;
    width: 120px !important;
    z-index: 1;

    .popover-body {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;

      button {
        font-size: 0.8rem;
      }

      button:hover {
        background-color: #37b0ef6b;
        text-decoration: none !important;
      }
    }
  }
}

.comment-popover {
  mdb-popover-container {
    width: auto !important;
    z-index: 0;

    .popover-body {
      padding: 0.3rem;
    }
  }
}

.other-menu-color {
  background: -webkit-linear-gradient(40deg, #457da9, #00558c) !important;
}

ng-select.custom_referral_dropdown>div.ng-select-container {
  height: 38px !important;
  font-size: 14px;
  box-shadow: 0 0.3125rem 0.625rem 0 rgb(0 0 0 / 4%);
}

// ****** Deepali Deshmukh *****

.sims_normal_question table {
  margin: auto;
  margin-top: 20px;
}

// ****** SIMs Harsh *****
.question_table .popover.bs-tether-element-attached-left::after,
.popover.popover-right::after {
  border-right-color: #043a4e !important;
}

.question_table mdb-popover-container .popover-body {
  border: 5px solid #043a4e !important;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 #111;
  padding: 0px;
}

.question_table .popover {
  width: 220px !important;
}

.grey_overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background-color: #717171;
  z-index: 100 !important;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  height: 100%;
}

.sims_scroll::-webkit-scrollbar-track {
  background: #e4cec6 !important;
}

.sims_scroll::-webkit-scrollbar-thumb {
  background: #d74c15 !important;
  border-radius: 10px;
}

.sims_normal_question {
  overflow-x: auto;
}

.sims_normal_question img {
  height: 610px;
  width: 1700px;
}

.modal-body img {
  width: 100%;
}

.draft .col-md-8 {
  margin: auto !important;
  border: 1px solid #b5c0f7 !important;
}

.draft .col-md-8 .row .col-md-12>p:nth-child(3) {
  color: #333 !important;
  text-align: left !important;
  margin-top: 15px;
}

.draft_header {
  background: #073345 !important;
  color: #fff !important;
  padding: 0.4em 1em;
  display: flex;
  justify-content: space-between;
}

.draft_header h4 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.sims_normal_question table tr td:nth-child(1) {
  padding: 6px;
}

.sims_normal_question table tr td:nth-child(2) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(3) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(4) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(5) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(6) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(7) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(8) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(9) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td:nth-child(10) {
  text-align: right !important;
  padding: 5px;
}

.sims_normal_question table tr td p:nth-child(1) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(2) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(3) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(4) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(5) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(6) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(7) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(8) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(9) {
  text-align: left !important;
  max-width: 400px;
}

.sims_normal_question table tr td p:nth-child(10) {
  text-align: left !important;
  max-width: 400px;
}

.single_line_text>table {
  display: none;
}

// .modal-dynamic{ width: 512px !important;}

// -- *********************** -- Ticket Style Starts -- *********************** -- //

.ticket {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}

.ticket_content {
  position: absolute;
  width: 100%;
}

.ticket>img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
}

.ticket_content>p:nth-child(1) {
  font-size: 32px;
  color: #fff;
}

.ticket_content>p:nth-child(2) {
  font-size: 48px;
  font-weight: 600;
  margin-top: 30px;
  display: inline-block;
  background: #2cb7ea;
  padding: 9px 30px;
  border: 4px dashed #3b87a4;
  color: #ffffff;
  cursor: copy;
}

.ticket_height {
  height: 440px;
}

.fix_height {
  height: 475px;
}

.close_icon {
  position: absolute;
  padding: 12px 22px;
  border-radius: 30px;
  right: 26px;
  top: -10px;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  z-index: 9;
}

.cutom_ticket_modal_content {
  background: transparent !important;
  box-shadow: none !important;
}

.facs_ticket_heading {
  font-size: 26px !important;
  color: #fff;
}

.facs_ticket_notice {
  font-size: 20px !important;
  color: darkgreen;
}

// -- *********************** -- Ticket Style Ends -- *********************** -- //

/* MAC Styles */

.central-meta {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #88b2df;
  border-radius: 0px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
}

.create-post {
  border-bottom: 1px solid #88b2df;
  display: block;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  padding-bottom: 12px;
  text-transform: capitalize;
  width: 100%;
  color: #515365;
  position: relative;
}

.create-post::before {
  content: "";
  height: 88%;
  left: -20px;
  position: absolute;
  top: -5px;
  width: 3px;
  background: #00558c;
}

/* MAC Styles */

@media (min-width: 320px) and (max-width: 575px) {

  ul.custom-lms-tabs-new.nav li a:not(.active),
  ul.custom-lms-tabs-new.nav li.active a.active {
    padding: 10px 14px !important;
  }

  ul.stepper.horizontal .step-new-content,
  ul.stepper.horizontal .step-new-content {
    padding: 0px !important;
  }
.custom_modal .modal-dialog{ padding: 30px 30px; }
.close_btn{ opacity: 1; right: -9px !important; top: 13px !important;}
.full_width{ min-width: 100%;}
}

@media (min-width: 576px) and (max-width: 767px) {

  ul.custom-lms-tabs-new.nav li a:not(.active),
  ul.custom-lms-tabs-new.nav li.active a.active {
    padding: 10px 35px !important;
  }
  .custom_modal .modal-dialog{ padding: 30px 30px; }
  .close_btn{ opacity: 1; right: -9px !important; top: 13px !important;}
  .full_width{ min-width: 100%;}
}

@media (min-width: 768px) and (max-width: 991px) {

  ul.custom-lms-tabs-new.nav li a:not(.active),
  ul.custom-lms-tabs-new.nav li.active a.active {
    padding: 10px 45px !important;
  }

  .card-wrapper {
    height: 635px !important;
  }

  // .miles-brand-logo{ margin-top: -10px !important;}
  .body-block-lg {
    margin-left: 94px;
  }

  .classic-tabs .nav li a {
    font-size: 12px !important;
    padding: 10px 12px !important;
  }
  .full_width{ min-width: 100%;}
}

@media (min-width: 768px) and (max-width: 1360px) {

  .new-study-tab .card,
  .new-study-tab .nav {
    width: 95%;
    display: flex;
    margin: auto;
    height: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  ul.custom-lms-tabs-new.nav li a:not(.active),
  ul.custom-lms-tabs-new.nav li.active a.active {
    padding: 10px 50px !important;
  }

  .card-wrapper {
    height: 790px !important;
  }

  h4.form_details_heading {
    font-size: 1rem;
  }

  ul.cma_track li {
    height: auto;
  }

  ul.cma_track li span {
    top: 66%;
  }
  .full_width{ min-width: 100%;}
}

@media only screen and (max-width: 991px) {
  ul.cma_track {
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .cma_track li a:not(.active) {
    padding: 10px 15px !important;
    font-size: 22px;
  }

  ul.cma_track li a {
    font-size: 22px;
  }

  .cma_track li a.active {
    padding: 12px 14px !important;
  }
}

@media (max-width: 992px) {
  .navbar .nav-item .nav-link {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

// -- ********************* -- Bridge Course Table Style Begins -- ******************************** -- //

div.bridge-course-table>table.table>tbody tr {

  td,
  td>a {
    font-family: var(--san_francisco_bold);
    padding: 10px;
    border-radius: 10px;
    color: #ffffff;
  }

  td.bec_txt>a {
    background-color: #F6BD60 !important;
  }

  td.reg_txt>a {
    background-color: #E63946 !important;
  }

  td.far_txt>a {
    background-color: #457B9D !important;
  }

  td.aud_txt>a {
    background-color: #84A59D !important;
  }
}

// -- ********************** -- Bridge Course Table Style Ends -- ********************************* -- //

table.reports_table tbody tr td p img {
  width: 100%;
}

.form-wizard {
  padding: 5px 20px 25px;
  border: 1px solid #d0dde1;
  background-color: #e8edf0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.form_content {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.form-group label {
  color: #748286;
}

.w_50{ width: 50% !important;}

.reference_table2>table.table thead th {
  border-top: none;
  background: #84A59D;
  /*background: #efefef;*/
  font-weight: 600;
  font-size: 15px;
  color: #535353;
  border: 1px solid #bababa;
  vertical-align: middle;
  padding: 0.6rem 16px;
}

.reference_table2>table.table td {
  font-size: 15px;
  line-height: 18px;
  color: #535353;
  font-weight: 500;
  background: #fefefe;
  border: 1px solid #bababa;
  vertical-align: middle;
  padding: 10px 8px;
}

// -- *********************** -- Evaluation Instructions Style Begins -- *********************** -- //

.custom-evaluation-instruction {
  .accordion .card .card-header {
    color: #0c3a5d !important;
    padding: 0.7rem 1.5rem !important;

    a h5 {
      font-weight: 500 !important;
      font-size: 1.1rem;
    }
  }

  .mdb-accordion-indicator {
    top: 15px !important;
  }

  .md-accordion .card:first-of-type,
  .md-accordion .card:not(:first-of-type):not(:last-of-type) {
    border: 1px solid #0c2d46 !important;
    border-top: 0px !important;
  }
}

// -- *********************** -- Evaluation Instructions Style Ends -- *********************** -- //
.mac-registration-form {

  input.form-control.ng-dirty.ng-touched.ng-invalid,
  ng-select.form-control.ng-dirty.ng-touched.ng-invalid,
  textarea.form-control.ng-dirty.ng-touched.ng-invalid {
    border: 2px solid #f44336 !important;
    border-radius: 4px !important;
  }

  // input.form-control.ng-dirty.ng-touched.ng-invalid,input.form-control.ng-invalid,ng-select.ng-invalid,textarea.form-control.ng-invalid{border: 2px solid #f44336 !important;border-radius: 4px !important; }
  // input.form-control.ng-invalid{border: 2px solid #f44336 !important;}
}

.carousel-indicators {
  display: none;
}

.post_Carousel .carousel {
  position: relative;
  height: 300px !important;
  border-radius: 0px !important;
}

.post_Carousel .carousel .slide {
  border-radius: 0px !important;
}

.post_Carousel .control.prev {
  background: #000;
  width: 35px;
  height: 35px;
  top: 40%;
  left: 20px !important;
}

.post_Carousel .control.next {
  background: #000;
  width: 35px;
  height: 35px;
  top: 40%;
  right: 20px !important;
}

.post_Carousel .control {
  font-size: 2.3em !important;
  padding: 0 !important;
}

.post_Carousel .arrow.left {
  position: relative;
  left: 13px;
}

.post_Carousel .arrow.right {
  position: relative;
  right: -7px;
}

.post_Carousel .arrow {
  border-left: 0.09em solid white !important;
  border-bottom: 0.09em solid white !important;
  width: 0.4em !important;
  height: 0.4em !important;
}

.scrollbar-linkedin-post::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar-linkedin-post::-webkit-scrollbar-thumb {
  background-color: #0379b2;
}

.md-form.md-outline label.dob_active {
  transform: translateY(-13px) scale(0.8);
  background: #fff;
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
  left: 8px;
}

.icon_box .popover-body {
  background: #212529 !important;
  color: #fff !important;
  border-radius: 3px;
}

.icon_box .popover.bs-tether-element-attached-right::after,
.icon_box .popover.popover-left::after {
  right: -10px;
  margin-top: -10px;
  border-left-color: #212529 !important;
}

// .testing-mac-valid{
//   .ng-invalid{ border: 2px solid #f44336 !important;}
// }

// cma
/* Importing Bootstrap SCSS file. */
// body{ font-family: 'Open Sans', sans-serif; background: #eff2f6;}
// :focus{ outline: none !important; border: none;}
// ::-webkit-scrollbar {
//   width:7px;
//   height: 7px;
// }
// ::-webkit-scrollbar-track{
//   background: #cfcfcf;
// }
// ::-webkit-scrollbar-thumb{
//   background: rgba(16, 90, 128, 0.99);
//   border-radius: 7px;
// }
cma-ques-set {
  & p {
    margin: 0px;
    padding: 0px;
  }

  & tbody {
    border: 1px solid #a5a5a5;
  }

  & td {
    padding: 5px;
    border: none;
  }

  & tr:nth-child(even) {
    background-color: #e4e4e4;
  }

  & tr:nth-last-child(1) {
    background-color: #cccccc;
  }
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background: rgba(16, 90, 128, 0.99);
  border-radius: 10px;
}

#tab-scroller::-webkit-scrollbar {
  width: 7px;
}

#tab-scroller::-webkit-scrollbar-thumb {
  background-color: #acacac;
}

#tab-scroller::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  margin: 0px 4px 0;
}

// search-list
mdb-accordion.header_col .card .card-header {
  padding: 1.5rem 1.5rem !important;
  border-radius: 6px !important;
  color: #226598 !important;
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #00000000;
  border: 1px solid rgb(15 92 145 / 23%);
  height: 166px;
}

mdb-accordion.header_col .card .card-header h5 {
  font-size: 1rem !important;
}

.mdb-accordion-indicator {
  top: 30px !important;
}

.fix_btn_width {
  width: 90px !important;
  background: #0044ba;
  text-transform: uppercase;
  font-size: 13px;
  border: none;
  color: #fff;
  padding: 0.4rem 0.75rem;
  border-radius: 7px;
  margin: 0px 0px;
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
  transition: all 0.2s ease-in-out;
}

.fix_btn_width:hover {
  background-color: #013ba1;
  box-shadow: 0 5px 11px #0000002e, 0 4px 15px #00000026;
  outline: 0;
  border: none;
  color: #fff;
}

.fix_btn_width:focus {
  background: #013ba1;
  border: none;
  color: #fff;
}

.btn_new:hover {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.blue_gradient {
  background: linear-gradient(358deg, #669eff, #0044ba);
}

.text-normal {
  text-transform: none !important;
}

.p-m-0 {
  padding: 0px !important;
  margin: 0px !important;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #f1b006;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0px;
  background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.c100.center {
  float: none;
  margin: 0 auto;
}

.c100.big {
  font-size: 240px;
}

.c100.small {
  font-size: 80px;
}

.c100>span {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 50%;
  width: 5em;
  line-height: 1.2em;
  font-size: 18px;
  color: #f1b006;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}

.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}

.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  -webkit-transform: rotate(46.800000000000004deg);
  -moz-transform: rotate(46.800000000000004deg);
  -ms-transform: rotate(46.800000000000004deg);
  -o-transform: rotate(46.800000000000004deg);
  transform: rotate(46.800000000000004deg);
}

.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}

.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}

.c100.p21 .bar {
  -webkit-transform: rotate(75.60000000000001deg);
  -moz-transform: rotate(75.60000000000001deg);
  -ms-transform: rotate(75.60000000000001deg);
  -o-transform: rotate(75.60000000000001deg);
  transform: rotate(75.60000000000001deg);
}

.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c100.p26 .bar {
  -webkit-transform: rotate(93.60000000000001deg);
  -moz-transform: rotate(93.60000000000001deg);
  -ms-transform: rotate(93.60000000000001deg);
  -o-transform: rotate(93.60000000000001deg);
  transform: rotate(93.60000000000001deg);
}

.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}

.c100.p31 .bar {
  -webkit-transform: rotate(111.60000000000001deg);
  -moz-transform: rotate(111.60000000000001deg);
  -ms-transform: rotate(111.60000000000001deg);
  -o-transform: rotate(111.60000000000001deg);
  transform: rotate(111.60000000000001deg);
}

.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}

.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  -webkit-transform: rotate(133.20000000000002deg);
  -moz-transform: rotate(133.20000000000002deg);
  -ms-transform: rotate(133.20000000000002deg);
  -o-transform: rotate(133.20000000000002deg);
  transform: rotate(133.20000000000002deg);
}

.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}

.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  -webkit-transform: rotate(151.20000000000002deg);
  -moz-transform: rotate(151.20000000000002deg);
  -ms-transform: rotate(151.20000000000002deg);
  -o-transform: rotate(151.20000000000002deg);
  transform: rotate(151.20000000000002deg);
}

.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}

.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  -webkit-transform: rotate(169.20000000000002deg);
  -moz-transform: rotate(169.20000000000002deg);
  -ms-transform: rotate(169.20000000000002deg);
  -o-transform: rotate(169.20000000000002deg);
  transform: rotate(169.20000000000002deg);
}

.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  -webkit-transform: rotate(187.20000000000002deg);
  -moz-transform: rotate(187.20000000000002deg);
  -ms-transform: rotate(187.20000000000002deg);
  -o-transform: rotate(187.20000000000002deg);
  transform: rotate(187.20000000000002deg);
}

.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}

.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  -webkit-transform: rotate(205.20000000000002deg);
  -moz-transform: rotate(205.20000000000002deg);
  -ms-transform: rotate(205.20000000000002deg);
  -o-transform: rotate(205.20000000000002deg);
  transform: rotate(205.20000000000002deg);
}

.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}

.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  -webkit-transform: rotate(223.20000000000002deg);
  -moz-transform: rotate(223.20000000000002deg);
  -ms-transform: rotate(223.20000000000002deg);
  -o-transform: rotate(223.20000000000002deg);
  transform: rotate(223.20000000000002deg);
}

.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}

.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  -webkit-transform: rotate(241.20000000000002deg);
  -moz-transform: rotate(241.20000000000002deg);
  -ms-transform: rotate(241.20000000000002deg);
  -o-transform: rotate(241.20000000000002deg);
  transform: rotate(241.20000000000002deg);
}

.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}

.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  -webkit-transform: rotate(266.40000000000003deg);
  -moz-transform: rotate(266.40000000000003deg);
  -ms-transform: rotate(266.40000000000003deg);
  -o-transform: rotate(266.40000000000003deg);
  transform: rotate(266.40000000000003deg);
}

.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  -webkit-transform: rotate(284.40000000000003deg);
  -moz-transform: rotate(284.40000000000003deg);
  -ms-transform: rotate(284.40000000000003deg);
  -o-transform: rotate(284.40000000000003deg);
  transform: rotate(284.40000000000003deg);
}

.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}

.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  -webkit-transform: rotate(302.40000000000003deg);
  -moz-transform: rotate(302.40000000000003deg);
  -ms-transform: rotate(302.40000000000003deg);
  -o-transform: rotate(302.40000000000003deg);
  transform: rotate(302.40000000000003deg);
}

.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}

.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  -webkit-transform: rotate(320.40000000000003deg);
  -moz-transform: rotate(320.40000000000003deg);
  -ms-transform: rotate(320.40000000000003deg);
  -o-transform: rotate(320.40000000000003deg);
  transform: rotate(320.40000000000003deg);
}

.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}

.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  -webkit-transform: rotate(338.40000000000003deg);
  -moz-transform: rotate(338.40000000000003deg);
  -ms-transform: rotate(338.40000000000003deg);
  -o-transform: rotate(338.40000000000003deg);
  transform: rotate(338.40000000000003deg);
}

.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}

.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  -webkit-transform: rotate(356.40000000000003deg);
  -moz-transform: rotate(356.40000000000003deg);
  -ms-transform: rotate(356.40000000000003deg);
  -o-transform: rotate(356.40000000000003deg);
  transform: rotate(356.40000000000003deg);
}

.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100:hover>span {
  width: 3.33em;
  line-height: 1.33em;
  font-size: 0.2em;
  color: #f1b006;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}

.breadcrumb_new .breadcrumb {
  background-color: transparent !important;
  margin: 0px 0px 25px;
  padding: 0px 0px;
  font-size: 16px;
  font-weight: 600;
}

.blue_text2 {
  color: #60a3e1;
}

.breadcrumb-item.active {
  color: #1d1f20 !important;
}

// .outer_box{margin-top: 60px; border-radius: 15px; background: #fff; padding: 5px; box-shadow: -8px 12px 18px 0 #f1f4fb; background: rgb(86 145 200 / 20%);}
.outer_box {
  margin-top: 60px;
  border-radius: 15px;
  padding: 15px;
}

.inner_box {
  border-radius: 15px;
  padding: 20px;
  position: relative;
  z-index: 1;
  background: inherit;
  background: rgba(255, 255, 255, 0.3);
}

.sub_topic_header .card {
  background: transparent !important;
}

.sub_topic_header .card-header {
  color: #606262;
  font-size: 0.95rem;
  padding: 9px 9px;
  background: #fff !important;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #a9c9dd;
  transition: all 0.4s ease;
}

.accordion_content_box>p {
  font-size: 14px;
  color: #535353;
  line-height: 20px;
  font-weight: 400;
  margin: 0px 0px 12px;
}

.divider {
  margin: 0px 0px 0px;
  border: none;
  height: 1px;
  background-color: #ededed;
}

.sub_topic_accordion .md-accordion .card {
  border: 1px solid #fff !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  border-radius: 14px !important;
  box-shadow: rgb(255 255 255 / 50%) -20px -20px 45px inset, rgb(0 0 0 / 1%) 10px 10px 20px,
    rgb(0 0 0 / 4%) 5px 5px 10px;
  background: rgba(255, 255, 255, 0.5);
}

.sub_topic_accordion .accordion .card .card-header {
  color: #606262;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
}

.sub_topic_accordion .accordion .card .card-header h5 {
  font-weight: 600 !important;
}

.accord_subheading {
  color: #606262;
  font-size: 14px;
  display: block;
  font-weight: 600;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.sub_topic_accordion .mdb-accordion-indicator {
  transform-origin: center !important;
  top: 22px !important;
  right: -7px !important;
  color: #606262;
  border-radius: 5px;
}

.sub_topic_accordion .mdb-accordion-indicator::after {
  padding: 3px !important;
}

.left_icon2 {
  background: linear-gradient(to right, #669eff, #4473c3);
  width: 42px;
  height: 42px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding-top: 12px;
  margin-right: 15px;
}

.left_icon2 {
  background: linear-gradient(to right, #669eff, #4473c3);
  width: 42px;
  height: 42px;
  min-width: 42px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding-top: 12px;
  margin-right: 15px;
}

.sub_topic_accordion .md-accordion .card .card-body {
  padding: 0px 0px !important;
}

.sub_topic_accordion .md-accordion:last-child {
  margin-bottom: 0px;
}

.dropdown-toggle::after {
  margin-left: 0.155em !important;
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
}

.profile_dropdown_menu .dropdown-item:hover,
.dropdown-item:focus {
  color: #6e7b8f !important;
  text-decoration: none;
  background-color: #f0f1f7 !important;
  box-shadow: none !important;
}

.modal-dialog .modal-content {
  border: 0;
}

.custom_header {
  padding: 15px !important;
  background-color: #fcfcfc;
  border-radius: 10px 10px 0px 0px !important;
}

.custom_footer {
  padding: 15px !important;
  background-color: #fcfcfc;
  border-radius: 10px 10px 0px 0px !important;
}

.custom_header>h4 {
  color: #2e3849;
  font-weight: 600;
  font-size: 16px;
}

.custom_footer {
  padding: 15px !important;
  background-color: #fcfcfc;
  border-radius: 0px 0px 10px 10px !important;
}

.modal_transparent_header>h4 {
  color: #013ba1;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.modal_transparent_header svg {
  position: relative;
  top: 3px;
}

.custom_body {
  padding: 15px;
  border-radius: 10px;
}

.download_btn {
  border-radius: 5px !important;
  padding: 6px 9px !important;
  font-size: 13px !important;
  margin: 0px !important;
  white-space: nowrap;
  background: #0044ba !important;
  color: #fff !important;
}

// .modal-backdrop { display: none;}
.tooltip-inner {
  max-width: 500px !important;
}

.breadcrumb_box .breadcrumb {
  background: transparent !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #b0d0eb;
  content: "/";
}

.fix_width {
  max-width: 1100px !important;
}

.essay_modal_header {
  background-color: #e0e0e0;
  padding: 10px 15px !important;
  border-radius: 10px 10px 0px 0px !important;
  border: 5px solid #f2f5f7;
}

.essay_modal_header>h4 {
  color: #2e3849;
  font-weight: 600;
  font-size: 16px;
  display: flex;
}

.essay_close_icon {
  background: #fff !important;
  padding: 5px 10px !important;
  opacity: 1;
  right: 27px !important;
  top: 26px !important;
}

.essay_modal_body {
  background: #f2f5f7;
  border-radius: 0px 0px 10px 10px !important;
  padding: 5px;
}

.course_feedback_text {
  font-size: 14px;
  margin: 0px 0px;
  font-weight: 500;
  margin-top: 5px;
}

.course_feedback_text span {
  font-weight: 600;
}

.essay_feedback_modal {
  width: 600px;
}

.stepper-horizontal li:hover {
  background-color: #f5f1e5 !important;
}

.stepper-horizontal li.active {
  background-color: #f5f1e5 !important;
}

ul.stepper li a {
  font-weight: 500;
  color: #212529 !important;
}

ul.stepper li a .label {
  color: #212529 !important;
}

.stepper-horizontal li {
  margin: 0px 10px;
}

ul.stepper li.active a .circle {
  background-color: #297398 !important;
}

.w_100 {
  width: 50%;
}

.ck-content {
  min-height: 200px !important;
  border: 1px solid #c4c4c4 !important;
  background: #fff !important;
}

.question_section h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px !important;
}

.question_section p {
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px !important;
}

.classic-tabs .tab-content.card {
  box-shadow: -8px 12px 18px 0 #f1f4fb;
  border-radius: 0px 0px 15px 15px;
  padding: 30px 40px;
}

.classic-tabs .nav {
  border-radius: 15px 15px 0 0 !important;
}

.new-study-tab .nav {
  border-radius: 10px 10px 0px 0px !important;
}

.md-accordion .card,
.md-accordion .card:first-of-type,
.md-accordion .card:not(:first-of-type):not(:last-of-type) {
  border: 0px solid #e0e0e0 !important;
}

.mcqs_result_table th,
.mcqs_result_table td {
  vertical-align: middle;
}

.classic-tabs .nav li a {
  font-size: 16px;
  padding: 14px 18px;
}

.md-accordion .card {
  border: none !important;
}

.classic-tabs .tab-content {
  padding: 30px 40px;
  background: #fff !important;
  box-shadow: none !important;
  border-radius: 0px 0px 15px 15px;
}

.cust-cma-subject-accord .card,
.cust-cma-subject-accord .card:first-of-type,
.cust-cma-subject-accord .card:not(:first-of-type):not(:last-of-type) {
  border: 1px solid #fff !important;
}

// feedback modal
ul.nav.classic-tabs.tabs-blue.cma_tab li a {
  font-size: 14px !important;
  padding: 14px !important;
}

/* MAC Styles */

.post_Carousel .carousel {
  position: relative;
  height: 300px !important;
  border-radius: 0px !important;
}

.post_Carousel .carousel .slide {
  border-radius: 0px !important;
}

.post_Carousel .control.prev {
  background: #000;
  width: 35px;
  height: 35px;
  top: 40%;
  left: 20px !important;
}

.post_Carousel .control.next {
  background: #000;
  width: 35px;
  height: 35px;
  top: 40%;
  right: 20px !important;
}

.post_Carousel .control {
  font-size: 2.3em !important;
  padding: 0 !important;
}

.post_Carousel .arrow.left {
  position: relative;
  left: 13px;
}

.post_Carousel .arrow.right {
  position: relative;
  right: -7px;
}

.post_Carousel .arrow {
  border-left: 0.09em solid white !important;
  border-bottom: 0.09em solid white !important;
  width: 0.4em !important;
  height: 0.4em !important;
}

.scrollbar-linkedin-post::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar-linkedin-post::-webkit-scrollbar-thumb {
  background-color: #0379b2;
}

.md-form.md-outline label.dob_active {
  transform: translateY(-13px) scale(0.8);
  background: #fff;
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
  left: 8px;
  color: #757575;
}

.icon_box .popover-body {
  background: #212529 !important;
  color: #fff !important;
  border-radius: 3px;
}

.icon_box .popover.bs-tether-element-attached-right::after,
.icon_box .popover.popover-left::after {
  right: -10px;
  margin-top: -10px;
  border-left-color: #212529 !important;
}

.form-wizard {
  padding: 5px 20px 25px;
  border: 1px solid #d0dde1;
  background-color: #e8edf0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.form_content {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.form-group label {
  color: #748286;
}

.reference_table2>table.table thead th {
  border-top: none;
  background: #e6eef5;
  /*background: #efefef;*/
  font-weight: 600;
  font-size: 15px;
  color: #535353;
  border: 1px solid #bababa;
  vertical-align: middle;
  padding: 0.6rem 16px;
}

.reference_table2>table.table td {
  font-size: 15px;
  line-height: 18px;
  color: #535353;
  font-weight: 500;
  background: #fefefe;
  border: 1px solid #bababa;
  vertical-align: middle;
  padding: 10px 8px;
}

.central-meta {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #88b2df;
  border-radius: 0px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
}

.create-post {
  border-bottom: 1px solid #88b2df;
  display: block;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  padding-bottom: 12px;
  text-transform: capitalize;
  width: 100%;
  color: #515365;
  position: relative;
}

.create-post::before {
  content: "";
  height: 88%;
  left: -20px;
  position: absolute;
  top: -5px;
  width: 3px;
  background: #00558c;
}

.mob_heading {
  font-size: 16px;
  color: #0044ba;
  font-weight: 600;
  margin-bottom: 20px;
  display: none;
  border-bottom: 1px solid #afb5be;
  padding-bottom: 10px;
}

.feedback_button {
  position: fixed;
  right: -58px;
  top: 50%;
  z-index: 9;
  transform: rotate(-90deg);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 43%);
  border-radius: 5px 5px 0px 0px !important;
  // background-color: #0145ba !important;
  background-color: #06345b !important;
  font-size: 14px;
  padding: 10px 20px 10px 0px !important;
  height: 40px;
  margin: 0px !important;
  letter-spacing: 1px;
}

.feedback_button span {
  // background: #094dc0;
  background: #06345b;
  font-size: 16px;
  padding: 12px 10px !important;
  border-radius: 5px 0px 0px 0px;
  margin-right: 18px;
}

.feedback_header {
  // background: #0145ba;
  background: #06345b;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0px;
}

.feedback_footer {
  background: #fbfbfb;
  border-radius: 0px !important;
}

.classic-tabs .nav li:first-child,
.new-study-tab .nav li:first-child {
  margin-left: 0px !important;
}

.classic-tabs .nav li:last-child {
  margin-right: 0px !important;
}

ul.custom-lms-tabs-new.nav li a:not(.active),
ul.custom-lms-tabs-new.nav li.active a.active {
  padding: 10px 40px !important;
}

.custom_video_ratio .plyr__video-embed {
  aspect-ratio: auto !important;
}

.select_state .ng-select .ng-select-container {
  border: 1px solid #ebeef2 !important;
}

.transparent_nav {
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: none !important;
  backdrop-filter: blur(12px);
  border-bottom: 1px solid #fff;
}

.transparent_sidebar {
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: none !important;
  backdrop-filter: blur(12px);
  border-right: 1px solid #fff;
}

/* MAC Styles */

.btn-blue-new {
  background: #0044ba;
  color: #fff !important;
}

.modal_transparent_bg {
  background: rgb(255 255 255 / 51%);
  backdrop-filter: blur(5px);
  border-radius: 14px !important;
}

.modal_transparent_header {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 14px 14px 0px 0px !important;
  padding: 15px !important;
  border-bottom: 1px solid rgb(255 255 255 / 40%);
}

.browser-default {
  background-color: rgb(255 255 255 / 2%);
  border: 1px solid rgb(255 255 255 / 50%);
  box-shadow: rgb(255 255 255 / 50%) -20px -20px 45px inset, rgb(0 0 0 / 1%) 10px 10px 20px,
    rgb(0 0 0 / 4%) 5px 5px 10px;
  color: #333333;
}

.total_box {
  background-color: rgb(255 255 255 / 2%);
  border: 1px solid rgb(255 255 255 / 50%);
  box-shadow: rgb(255 255 255 / 50%) -20px -20px 45px inset, rgb(0 0 0 / 1%) 10px 10px 20px,
    rgb(0 0 0 / 4%) 5px 5px 10px;
  color: #333333;
}

.modal_transparent_footer {
  background: rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgb(255 255 255 / 40%);
}

.transparent_backdrop {
  background: rgba(255, 255, 255, 0.4) !important;
  backdrop-filter: blur(5px);
}

.content_update_modal .modal-dynamic {
  background: rgb(255 255 255 / 51%);
  backdrop-filter: blur(5px);
  border-radius: 14px !important;
}

.dropdown_new .dropdown-item-new {
  color: #333333 !important;
  font-weight: 500 !important;
  display: block !important;
  border-bottom: 1px solid #f1f1f1;
}

.dropdown_new .dropdown-item-new:hover {
  box-shadow: rgb(255 255 255 / 50%) -20px -20px 45px inset, rgb(0 0 0 / 1%) 10px 10px 20px,
    rgb(0 0 0 / 4%) 5px 5px 10px;
  background: rgba(255, 255, 255, 0.81) !important;
  border-radius: 0px;
  color: #0044ba !important;
  background: #fff !important;
}

//.content_update_modal { background: rgb(255 255 255 / 51%); backdrop-filter: blur(5px); border-radius: 14px !important;}

.multiselect .mdb-select-wrapper {
  box-shadow: 0 0.3125rem 0.625rem 0 rgb(0 0 0 / 4%);
  border: 1px solid #dadce0;
  border-radius: 4px;
  text-align: left;
}

.multiselect .mdb-select-value {
  border-radius: 4px;
  padding: 0.375rem 0.75rem !important;
}

.multiselect label {
  color: #212529 !important;
}

.edit_profile_steps ul {
  border-radius: 8px;
  background: #0044ba !important;
}

.cropped img {
  margin: -12% 0px 0px 0px !important;
  width: 100%;
  height: auto;
}

// .book_order_accordion{ padding-left: ;}
.book_order_accordion .accordion .card {
  border: 1px solid #b6b9bd  !important;
}

.book_order_accordion .accordion .card .card-header {
  border-bottom: 1px solid #cdcdcd;
  background: #2043ba30;
  color: #2043ba;
  border-radius: 0px;
  padding: 15px 25px 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--san_francisco_bold);
}

.book_order_accordion .accordion .card {
  margin-top: 10px;
}

.book_order_accordion .accordion .card .card-header a h5 {
  font-size: 14px;
  font-weight: 600;
}

.book_order_accordion .mdb-accordion-indicator {
  top: 15px !important;
}

.book_order_accordion .accordion .card .card-body {
  padding: 15px !important;
}

.book_order_accordion .accordion .card .card-body p {
  margin: 0px;
  color: #617189;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

// AICPA faq
.faq_accordion .accordion .card {
  border: 1px solid #d9dcdf !important;
  border-radius: 5px;
}

.faq_accordion .accordion .card .card-header {
  border-bottom: 1px solid #b6b9bd !important;
  // background: #eff1f2;
  background: rgba(32, 67, 186, 0.1882352941);
  // color: #61718a;
  color: #2043ba;
  // border-radius: 5px;
  padding: 18px;
}

.faq_accordion .accordion .card {
  margin-top: 10px;
  border-color: #b6b9bd !important;
}

// mentoring
.stepper-horizontal {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
}

.stepper-horizontal li {
  transition: 0.5s;
  display: flex;
  align-items: center;
  flex: 0 !important;
  position: relative;
}

ul.stepper li a {
  padding: 1rem 0.8rem;
  text-align: center;
  width: max-content;
}

ul.stepper li.active a .label {
  color: rgba(0, 68, 186, 1) !important;
  border-bottom: 3px solid #0044ba;
}

.faq_accordion .accordion .card .card-header a h5 {
  font-size: 16px;
  font-weight: 500;
  // color: #373a3c;
}

.faq_accordion .mdb-accordion-indicator {
  top: 20px !important;
}

.faq_accordion .accordion .card .card-body {
  padding: 15px !important;
  background: rgba(252, 246, 234, 0.768627451);
  border-radius: 0px 0px 5px 5px;
}

.faq_accordion .accordion .card .card-body p {
  margin: 0px;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.aicpa_accordion .accordion .card,
.fof_accordion .accordion .card {
  border: 1px solid #ccc !important;
  border-radius: 5px;
  margin-top: 10px;
  // box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
}

.aicpa_accordion .accordion .card .card-header {
  border: 1px solid #06345B;
  border-radius: 5px;
  padding: 18px;
}

.fof_accordion .accordion .card .card-header {
  border: 1px solid rgb(204 203 203 / 14%);
  border-radius: 5px;
  padding: 18px;
}

.fof_accordion .accordion .card .card-header a h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.aicpa_accordion .accordion .card .card-header a h5 {
  font-size: 16px;
  font-weight: 500;
  color: #06345B;
  font-weight: 600;
}

.aicpa_accordion .mdb-accordion-indicator {
  top: 20px !important;
}

.aicpa_accordion .accordion .card .card-body {
  padding: 15px !important;
  background: #fff;
  border-left: 1px solid rgb(66 133 244 / 50%);
  border-right: 1px solid rgb(66 133 244 / 50%);
  border-bottom: 1px solid rgb(66 133 244 / 50%);
}

.aicpa_accordion .accordion .card .card-body p {
  margin: 0px;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.aicpa_sub_accordion .accordion .card {
  border: 1px solid #d9dcdf !important;
  border-radius: 5px;
}

.fof_sub_accordion .accordion .card .card-header a h5 {
  font-size: 14.5px;
  font-weight: 500;
  color: #000;
}

.fof_sub_accordion .accordion .card .card-header {
  background: #f9f9f9;
  padding: 15px 15px 0 15px;
}

.fof_sub_accordion .mdb-accordion-indicator,
.fof_accordion .mdb-accordion-indicator {
  top: 20px !important;
}

.aicpa_sub_accordion .accordion .card .card-header {
  border: 1px solid #06345B;
  background: #f5f5f5;
  color: #61718a;
  border-radius: 5px;
  padding: 1rem 1rem;
  box-shadow: none !important;
}

.aicpa_sub_accordion .accordion .card {
  margin-top: 10px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
}

.aicpa_sub_accordion .accordion .card .card-header a h5 {
  font-size: 14.5px;
  font-weight: 500;
  color: #06345B;
}

.aicpa_sub_accordion .mdb-accordion-indicator {
  top: 20px !important;
}

.aicpa_sub_accordion .accordion .card .card-body {
  padding: 15px !important;
  border-left: 1px solid #06345B;
  border-right: 1px solid #06345B;
  border-bottom: 1px solid #06345B;
  background: #f5f5f5;
}

.aicpa_sub_accordion .accordion .card .card-body p {
  margin: 0px;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.subtopic {
  background: #9c466a;
  border: 1px solid #74377a;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  font-weight: 600;
}

.aicpa_btn {
  font-size: 14px !important;
}

.aicpa_sub_accordion .accordion .card .card-header a h5>span>ul,
.fof_sub_accordion .accordion .card .card-header a h5>span>ul {
  list-style-type: none;
  padding-left: 10px;
}

.upload_progress .progress {
  background: #ecf2ff !important;
  border-radius: 10px !important;
  height: 6px;
}

.upload_progress .progress-bar {
  background: #407bff !important;
}

.mcom_card .flip-container .front,
.mcom_card .flip-container .back {
  border-radius: 8px !important;
}

.jain_faq .accordion .card {
  border: 1px solid #dbdbdb !important;
  border-radius: 8px;
  box-shadow: none;
}

.jain_faq .accordion .card .card-header {
  border-bottom: 1px solid #eaeaea;
  background: #fff;
  color: #61718a;
  border-radius: 8px;
  padding: 18px;
}

.jain_faq .accordion .card {
  margin-top: 10px;
}

.jain_faq .accordion .card .card-header a h5 {
  font-size: 16px;
  font-weight: 500;
  color: #373a3c;
}

.jain_faq .mdb-accordion-indicator {
  top: 20px !important;
}

.jain_faq .accordion .card .card-body {
  padding: 15px !important;
}

.jain_faq .accordion .card .card-body p {
  margin: 0px;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.program_faq_accordion .accordion .card {
  border: 1px solid #d9dcdf !important;
  box-shadow: 0px 0px 17px -5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.program_faq_accordion .accordion .card .card-header {
  background: #ffffff;
  border-radius: 8px;
  color: #61718a;
  border-radius: 5px;
  padding: 18px;
  border-bottom: 1px solid #e9e9e9;
}

.program_faq_accordion .accordion .card {
  margin-top: 10px;
}

.program_faq_accordion .accordion .card .card-header a h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  font-family: "Montserrat", sans-serif;
  padding-right: 10px;
}

.program_faq_accordion .mdb-accordion-indicator {
  top: 20px !important;
}

.program_faq_accordion .accordion .card .card-body {
  padding: 15px 15px 15px !important;
}

.program_faq_accordion .accordion .card .card-body p {
  margin: 0px;
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-left: 5px;
  margin-top: 5px;
}

.certification_fix_width_modal {
  max-width: 650px;
}

.certification_fix_width_modal .modal-content {
  border-radius: 10px !important;
}

.paynow_fix_width_modal {
  max-width: 730px;
}

.paynow_fix_width_modal .modal-content {
  border-radius: 10px !important;
}

.aicpa_sub_accordion .accordion .card {
  border: 1px solid #d9dcdf !important;
  border-radius: 5px;
}

.aicpa_sub_accordion .accordion .card .card-header {
  border: 1px solid #06345B;
  background: #f5f5f5;
  color: #61718a;
  border-radius: 5px;
  padding: 1rem 1rem;
  box-shadow: none !important;
}

.aicpa_sub_accordion .accordion .card {
  margin-top: 10px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
}

.aicpa_sub_accordion .accordion .card .card-header a h5 {
  font-size: 14.5px;
  font-weight: 500;
  color: #06345B;
  font-weight: 600;
}

.aicpa_sub_accordion .mdb-accordion-indicator {
  top: 20px !important;
}

.aicpa_sub_accordion .accordion .card .card-body {
  padding: 15px !important;
  border-left: 1px solid #06345B;
  border-right: 1px solid #06345B;
  border-bottom: 1px solid #06345B;
  background: #f5f5f5;
}

.aicpa_sub_accordion .accordion .card .card-body p {
  margin: 0px;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.subtopic {
  background: #9c466a;
  border: 1px solid #74377a;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  font-weight: 600;
}

.aicpa_btn {
  font-size: 14px !important;
}

.aicpa_sub_accordion .accordion .card .card-header a h5>span>ul {
  list-style-type: none;
  padding-left: 10px;
}

.profile_modal_fix_width {
  max-width: 950px;
  height: 580px;
}

.profile_modal_fix_width .modal-content {
  border-radius: 10px !important;
}

.profile_progress .progress {
  // background: #b2c5fa !important;
  border-radius: 10px !important;
  background: #4caf5052 !important;
}

.profile_progress .progress-bar {
  // background: #1a43b3 !important;
  background: #4CAF50 !important;
}

.profile_modal_fix_width .modal-body {
  overflow-y: unset;
}

.date_box .md-form.md-outline input[type="text"] {
  border: none;
  border-bottom: 1px solid #b4b4b4;
  box-shadow: none;
  border-radius: 0px;
  width: 80%;
  display: block;
  background: transparent url("https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/feedback/profile/date.png") no-repeat 95% 12px;
  height: 45px;
  padding-left: 0px;
  padding-right: 50px;
}

.date_box input[type="text"] {
  border: 1px solid #b4b4b4;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 10px;
  width: 80%;
  display: block;
  background: transparent url("https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/feedback/profile/date.png") no-repeat 95% 12px;
  height: 45px;
  padding-left: 10px;
  padding-right: 50px;
}

.custom-autocomplete {
  .ng-autocomplete {
    width: 100% !important;
  }

  .autocomplete-container {
    border: 1px solid #b4b4b4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 80%;
    display: block;
    background: #fff url("https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/feedback/profile/search.png") no-repeat 10px 12px;
    height: 45px;
    padding-left: 40px;
    padding-right: 20px;
  }

  .autocomplete-container .input-container input {
    padding-left: 0px;
    border-radius: 10px;
  }
}

.custom_material_chips {
  .md-chip-list .md-chip {
    position: relative;
    margin-bottom: 10px;
  }

  .md-chip-list .md-chip .close {
    position: relative;
    right: 0px;
    cursor: pointer;
  }
}

.custom_parent_details .ng-select.ng-select-single .ng-select-container {
  background-color: #dde1e6;
  border: 2px solid #dde1e6;
  height: 44px;
  border-radius: 8px;
}

.jainAppPersonalData ng-select.ng-select .ng-select-container {
  height: 44px;
  border: 2px solid #dde1e6;
  border-radius: 8px;
}

.pathway_select {
  margin-top: 20px;
  width: 350px;
}

.pathway_select .ng-select.ng-select-single .ng-select-container {
  border-radius: 6px;
  border: none;
}

.range_text {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  white-space: nowrap;
  position: relative;
  top: -4px;
}

#range .range-field input[type="range"] {
  &::-webkit-slider-thumb {
    background: #0044ba !important;
  }

  &::-moz-range-thumb {
    background: #0044ba !important;
  }

  &::-ms-thumb {
    background: #0044ba !important;
  }
}

#location>.form-control {
  border: none;
}

.experience_box {
  width: 100%;
}

.experience_box>.ng-select .ng-select-container {
  border: none;
}

.dob_modal .modal-content {
  width: 1000px;
  margin: auto;
  background: url("https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/greetings_popup.jpg") no-repeat center bottom / cover;
  border-radius: 10px !important;
  position: relative;
}

.greetings_popup_icons {
  position: absolute;
  bottom: -14px;
  left: -33px;
  width: 140px;
  z-index: 9;
}

.form_card {
  width: 90%;
  margin-left: 5%;
  margin-top: -5%;
  box-shadow: 0 3px 25px #061acc1f;
  border: 1px solid #d9e2f1 !important;
  border-radius: 10px;
}

.fix_width_label {
  width: 110px;
}

.w_70 {
  width: 70% !important;
}

.dob_flex_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.dob_left_box {
  width: 47%;
}

.dob_right_box {
  width: 47%;
}

.h_400 {
  height: 400px;
}

.tab_accordion {
  padding: 0px 10px;
  margin: 30px 0px 0px;
}

.tab_accordion .accordion .card {
  border: 0px solid #d9dcdf !important;
  box-shadow: 0px 0px 17px -5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.tab_accordion .accordion .card .card-header {
  background: #ffffff;
  border-radius: 8px;
  color: #61718a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 18px;
  border-bottom: 1px solid #e9e9e9;
}

.tab_accordion .accordion .card {
  margin-top: 10px;
  background: transparent;
  box-shadow: none;
}

.tab_accordion .accordion .card .card-header a h5 {
  font-size: 16px;
  color: #000;
  font-family: var(--san_francisco_bold);
  padding-right: 10px;
  // margin-left: 15px;
}

.m1 {
  color: #06345B;
  font-size: 14px;
  line-height: 19px;
  border: 4px solid #06345B;
  border-radius: 23px;
  padding: 7px 0px;
  text-align: center;
  position: absolute;
  left: -20px;
  top: 7px;
  background: #fff;
  width: 42px;
}

.tab_accordion .mdb-accordion-indicator:after {
  border-width: 0 2px 2px 0;
  padding: 4.5px;
}

.tab_accordion .mdb-accordion-indicator {
  top: 21px !important;
}

.tab_accordion .accordion .card-body {
  padding-right: 0px;
}

.classRoom-session-Tabs .tab-content.card{ border-radius: 0px 0px 20px 20px; box-shadow: none !important; border-radius: 20px 20px; border: 1px solid #D3D2D2; padding: 24px;}
// .classic-tabs .nav.classic-tabs{ background-color: #e1e1df!important;}
.classic-tabs .nav li a{ color: #000000 !important; font-size: 16px !important;}
.classic-tabs .nav li a.active{ color: #000000 !important;  border-bottom: 3px solid;}

.iimi_pgca_tab_accordion {
  padding: 0px 0px;
  margin: 30px 0px 0px;
}

.iimi_pgca_tab_accordion .accordion .card {
  border-radius: 10px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1) !important ;
}

.iimi_pgca_tab_accordion .accordion .card .card-header {
  background: #06345B;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: none;
  padding: 18px;
  border-bottom: 1px solid #e9e9e9;
}

.iimi_pgca_tab_accordion .accordion .card {
  margin-top: 10px;
  box-shadow: none;
}

.iimi_pgca_tab_accordion .accordion .card .card-header a h5 {
  font-size: 16px;
  font-family: var(--san_francisco_bold);
  padding-right: 10px;
  // margin-left: 15px;
}

.iimi_pgca_tab_accordion .mdb-accordion-indicator:after {
  border-width: 0 2px 2px 0;
  padding: 4.5px;
}

.iimi_pgca_tab_accordion .mdb-accordion-indicator {
  top: 21px !important;
}

.dynamic_progressbar .progress-bar, .progress {
  height: 6px !important;
}
.dynamic_progressbar mdb-progress .progress{ background-color: #fff; border: 0.1px dotted #404d55; box-shadow: -1px 6px 23px -11px rgba(0,0,0,0.75); -webkit-box-shadow: -1px 6px 23px -11px rgba(0,0,0,0.75); -moz-box-shadow: -1px 6px 23px -11px rgba(0,0,0,0.75);}
.dynamic_progressbar mdb-progress .progress .progress-bar.progress-bar-warning{ background-color: #f5be44;}
.dynamic_progressbar mdb-progress .progress .progress-bar.progress-bar-info{ background-color: #3570db;}
.dynamic_progressbar mdb-progress .progress .progress-bar.progress-bar-success{ background-color: #90c372;}
.far_switch, .aud_switch, .bec_switch,.bar_switch,.tcp_switch,.isc_switch .reg_switch{ position: relative; left: 5px;}
.far_switch .custom-control-label::after{ background-color: #457b9d;}
.far_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #457b9d; border-color: #457b9d;}
.aud_switch .custom-control-label::after{ background-color: #85a59d;}
.aud_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #85a59d; border-color: #85a59d;}
.bec_switch .custom-control-label::after{ background-color: #f6bd60;}
.bec_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #f6bd60; border-color: #f6bd60;}
.reg_switch .custom-control-label::after{ background-color: #e63a46;}
.reg_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #e63a46; border-color: #e63a46;}
// .bar_switch .custom-control-label::after{ background-color: #f6bd60;}
// .bar_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #f6bd60; border-color: #f6bd60;}
.bar_switch .custom-control-label::after{ background-color: #F39F5A;}
.bar_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #F39F5A; border-color: #F39F5A;}
.tcp_switch .custom-control-label::after{ background-color: #CB6745;}
.tcp_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #F39F5A; border-color: #F39F5A;}
.isc_switch .custom-control-label::after{ background-color: #BDB17F;}
.isc_switch .custom-control-input:checked ~ .custom-control-label::before{ background-color: #F39F5A; border-color: #F39F5A;}
.glassmorphism_effect{ background: rgba( 255, 255, 255, 0.25 ) !important; backdrop-filter: blur( 14px ); -webkit-backdrop-filter: blur( 14px );}

@media (min-width: 320px) and (max-width: 575px) {
  ul.new-study-tab li {
    margin: 0 5px;
  }

  .new-study-tab .nav li:nth-child(1) a,
  .new-study-tab .nav li:nth-child(2) a,
  .new-study-tab .nav li:nth-child(3) a,
  .new-study-tab .nav li:nth-child(4) a,
  .new-study-tab .nav li:nth-child(5) a {
    padding: 14px 13px !important;
    font-size: 13px;
  }

  .navbar .nav-item .nav-link {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }

  .outer_box {
    margin-top: 60px;
    padding: 15px;
  }

  .essay_feedback_modal {
    width: 100%;
  }

  .stepper-horizontal {
    flex-direction: row;
  }

  .w_100 {
    width: 80%;
  }

  ul.stepper li a {
    padding: 0.5rem 0rem !important;
    width: 90px;
  }

  .mob_heading {
    display: block;
  }

  .feedback_header {
    border-radius: 10px 10px 0px 0px !important;
  }

  .feedback_footer {
    border-radius: 0px 0px 10px 10px !important;
  }

  .feedback_modal .modal-content {
    max-height: calc(100vh - 5rem);
  }

  .classRoom-session-Tabs .nav li a {
    padding: 20px 13px !important;
  }

  .classRoom-session-Tabs .tab-content {
    padding: 30px 20px 10px !important;
  }

  .program_faq_accordion .accordion .card .card-body p {
    font-size: 14px;
  }

  .program_faq_accordion .accordion .card .card-header a h5 {
    font-size: 14px;
  }

  .profile_modal_fix_width {
    max-width: auto;
  }

  .profile_modal_fix_width .modal-body {
    overflow-y: auto !important;
  }

  .custom-autocomplete {
    .autocomplete-container {
      width: 100%;
    }
  }

  .date_box .md-form.md-outline input[type="text"] {
    width: 100%;
  }

  .pathway_select {
    margin-top: 20px;
    width: 325px;
  }

  .dob_left_box {
    width: 100%;
  }

  .dob_right_box {
    width: 100%;
  }

  .greetings_popup_icons {
    display: none;
  }

  .form_card {
    margin-left: 0%;
    margin: 10px auto 0px;
    width: 315px;
  }

  .f_16 {
    font-size: 16px !important;
  }

  .h_400 {
    height: auto;
  }

  .tab_accordion .accordion .card .card-header a h5 {
    font-size: 14px;
    padding-right: 15px;
  }
  .grid{ margin: 100px 0px 20px 0px;}
  .pagination_box{ padding: 12px !important; font-size: 14px !important;}
  .f2f_tab{ display: flex; flex-wrap: nowrap; overflow-x: auto;}
  .f2f_tab .nav li a { padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .f2f_tab .nav li a.active{ padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .study_longue_outer_box{ 
  .card-body{ padding: 0px;}} 
  .tabs label{ flex-wrap: wrap; padding: 0.5rem 1rem !important;}
  .tab_icon{ display: block; width: 100%; text-align: center;} 
  .tabs label span i{ margin-right: 0px !important;}
  .inter_tab_icon{ width: 100%;
  i{ margin-right: 0px !important;}} 
}

@media (min-width: 576px) and (max-width: 767px) {
  .fix_width {
    max-width: 560px !important;
  }

  .mob_sps_btm {
    margin-bottom: 25px;
  }

  .essay_feedback_modal {
    width: 100%;
  }

  .stepper-horizontal {
    flex-direction: row;
  }

  .w_100 {
    width: 50%;
  }

  .mob_heading {
    display: block;
  }

  .feedback_header {
    border-radius: 10px 10px 0px 0px !important;
  }

  .feedback_footer {
    border-radius: 0px 0px 10px 10px !important;
  }

  .feedback_modal .modal-content {
    max-height: calc(100vh - 5rem);
  }

  .classRoom-session-Tabs .nav li a {
    padding: 20px 9px !important;
    font-size: 14px !important;
  }

  .classRoom-session-Tabs .tab-content {
    padding: 30px 20px 10px !important;
  }

  .profile_modal_fix_width {
    max-width: auto;
  }

  .profile_modal_fix_width .modal-body {
    overflow-y: auto !important;
  }

  .custom-autocomplete {
    .autocomplete-container {
      width: 100%;
    }
  }

  .date_box .md-form.md-outline input[type="text"] {
    width: 100%;
  }

  .pathway_select {
    margin-top: 20px;
    width: 350px;
  }

  .dob_left_box {
    width: 100%;
  }

  .dob_right_box {
    width: 100%;
  }

  .greetings_popup_icons {
    display: none;
  }

  .form_card {
    margin-left: 0%;
    margin: 10px auto 0px;
    width: 380px;
  }

  .f_16 {
    font-size: 16px !important;
  }

  .h_400 {
    height: auto;
  }

  .tab_accordion .accordion .card .card-header a h5 {
    font-size: 14px;
    padding-right: 15px;
  }
  .grid{ margin: 90px 0px 20px 0px;}
  .pagination_box{ padding: 12px !important; font-size: 16px !important;}
  .f2f_tab{ display: flex; flex-wrap: nowrap; overflow-x: auto;}
  .f2f_tab .nav li a { padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .f2f_tab .nav li a.active{ padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .tabs label{ flex-wrap: wrap; padding: 0.5rem 1rem !important;}
  .tab_icon{ display: block; width: 100%; text-align: center;} 
  .tabs label span i{ margin-right: 0px !important;}
  .inter_tab_icon{ width: 100%;
    i{ margin-right: 0px !important;}}
}

@media (min-width: 768px) and (max-width: 991px) {
  .fix_btn_width {
    font-size: 12px;
  }

  .fix_width {
    max-width: 600px !important;
  }

  .mob_heading {
    display: block;
  }

  .classic-tabs .nav li:first-child,
  .new-study-tab .nav li:first-child {
    margin-left: 0px !important;
  }

  .classic-tabs .nav li:last-child {
    margin-right: 0px !important;
  }

  ul.custom-lms-tabs-new.nav li a:not(.active),
  ul.custom-lms-tabs-new.nav li.active a.active {
    padding: 10px 5px !important;
    font-size: 14px !important;
  }

  .classRoom-session-Tabs .nav li a {
    padding: 20px 8px !important;
    font-size: 14px !important;
  }

  .classRoom-session-Tabs .tab-content {
    padding: 30px 20px 10px !important;
  }

  mdb-accordion-item-head.search-head .accordion .card .card-header {
    height: 183px;
  }

  .profile_modal_fix_width {
    max-width: 750px;
  }

  .dob_right_box {
    width: 75%;
  }

  .form_card {
    width: 100%;
    margin-left: 0%;
    margin-top: 0%;
  }

  .dob_left_box {
    width: 350px;
  }

  .dob_flex_box {
    justify-content: center;
  }
  .grid{ margin: 20px 5px 0px 265px;}
  .pagination_box{ padding: 12px !important; font-size: 16px !important;}
  .f2f_tab{ display: flex; flex-wrap: nowrap; overflow-x: auto;}
  .f2f_tab .nav li a { padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .f2f_tab .nav li a.active{ padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .tabs label{ padding: 0.5rem 1rem !important;}
  .inter_tab_icon{ width: 100%;
    i{ margin-right: 0px !important;}}
}

@media (min-width: 992px) and (max-width: 1199px) {
  .fix_width {
    max-width: 990px !important;
  }

  .w_100 {
    width: 60%;
  }

  .classic-tabs .nav li:first-child,
  .new-study-tab .nav li:first-child {
    margin-left: 0px !important;
  }

  .classic-tabs .nav li:last-child {
    margin-right: 0px !important;
  }

  ul.custom-lms-tabs-new.nav li a:not(.active),
  ul.custom-lms-tabs-new.nav li.active a.active {
    padding: 10px 20px !important;
  }

  .classRoom-session-Tabs .tab-content {
    padding: 30px 20px 10px !important;
  }

  mdb-accordion-item-head.search-head .accordion .card .card-header {
    height: 178px;
  }

  .jainAppPersonalData ng-select.ng-select .ng-select-container {
    height: 40px;
    border: 2px solid #dde1e6;
    border-radius: 8px;
  }

  .custom_parent_details .ng-select.ng-select-single .ng-select-container {
    height: 40px;
  }

  .dob_right_box {
    width: 51%;
  }
  .pagination_box{ padding: 12px !important; font-size: 16px !important;}
  .f2f_tab{ display: flex; flex-wrap: nowrap; overflow-x: auto;}
  .f2f_tab .nav li a { padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .f2f_tab .nav li a.active{ padding: 10px 10px !important; font-size: 14px; white-space: nowrap;}
  .audit_dashboard_card > .audit_text{ display: flex; align-items: center;}
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .classic-tabs .nav li:first-child {
    margin-left: 25px !important;
  }

  .new-study-tab .nav li:first-child {
    margin-left: 0 !important;
  }

  .classic-tabs .nav li:last-child {
    margin-right: 25px !important;
  }

  .jainAppPersonalData ng-select.ng-select .ng-select-container {
    height: 40px;
    border: 2px solid #dde1e6;
    border-radius: 8px;
  }

  .custom_parent_details .ng-select.ng-select-single .ng-select-container {
    height: 40px;
  }
  .grid { margin: 30px 0px 30px 270px;}
  .audit_dashboard_card > .audit_text{ display: flex; align-items: center;}
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .jainAppPersonalData ng-select.ng-select .ng-select-container {
    height: 40px;
    border: 2px solid #dde1e6;
    border-radius: 8px;
  }

  .custom_parent_details .ng-select.ng-select-single .ng-select-container {
    height: 40px;
  }
}

.progress_mdb mdb-progress .progress .progress-bar {
  height: 45px;
}

.progress_mdb mdb-progress .progress {
  height: 45px;
  margin-bottom: 0px;
}

.progress_mdb mdb-progress .progress .progress-bar.progress-bar-success {
  background-color: #06345B;
}



// .cma-part1-bg-color {background: linear-gradient(to right, #258fcd, #017bc6) !important;}
// .cma-part2-bg-color {background: linear-gradient(to right, #7cb71a, #86d532) !important;}

.cma-part1-sectionBg-color {
  background: linear-gradient(to right, #0044ba, #2c63c3) !important;
}

.cma-part2-sectionBg-color {
  background: linear-gradient(to right, #0044ba, #2c63c3) !important;
}

.cust-cma-subject-accord {
  mdb-accordion-item-head {
    border: 0px solid;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0px;
  }

  .card {
    border-radius: 10px 10px 0px 0px !important;
  }

  mdb-accordion-item-body {
    border: 0px solid;
    border-top: 0px;
  }
}

.part-1accord {
  border-color: #ffffff !important;
}

.part-2accord {
  border-color: #ffffff !important;
}

.cust-w-75 {
  max-width: 75% !important;
}

.part1Pagination {
  font-weight: 600 !important;

  .active,
  :hover {
    color: #0044ba !important;
    font-weight: 600 !important;
  }
}

.part2Pagination {
  font-weight: 600 !important;

  .active,
  :hover {
    color: #0044ba !important;
    font-weight: 600 !important;
  }
}

.part1PaginationArrow {
  color: #0044ba !important;
  font-weight: 600 !important;
}

.part2PaginationArrow {
  color: #0044ba !important;
  font-weight: 600 !important;
}

.notifiedSize {
  font-size: 15px;
}

.md-accordion mdb-accordion-item.bridgecourse-accordion {

  .card,
  .card:first-of-type {
    border-radius: 10px;
    border: 1px solid #e4e0cf !important;
    margin-top: 0.5rem;
    // margin-bottom: 1rem;

    mdb-accordion-item-head {
      .card-header {
        text-transform: capitalize !important;
        background: #f4f0e5;
        padding: 14px;
        border-radius: 10px 10px 0px 0px;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        // padding: 8px 35px 8px 10px;

        h5 {
          color: #000000;
          margin-right: 10px;
          font-family: var(--san_francisco_bold);
          font-size: 14px;
        }

        .mdb-accordion-indicator {
          top: 15px !important;
        }
      }
    }

    .card-body {
      padding: 1px !important;
      border-radius: 0px 0px 10px 10px;
    }
  }
}

// CMA new design

.md-accordion mdb-accordion-item.cma_acc {
  mdb-accordion-item-head {
    .card-header {
      padding: 0.8rem 0.5rem !important;
    }

    span {
      padding: 6px 6px;
      color: #000;
      margin-right: 10px;
      font-weight: 500;
      font-size: 14px;
    }

    .mdb-accordion-indicator {
      top: 22px !important;
      color: #000;
    }
  }

  .card-body {
    padding: 0px !important;
    border-radius: 0px !important;
  }
}

.subject-switch .slider {
  background-color: #fff !important;
}

.progressbar-step {
  width: 35%;

  mdb-progress div.progress {
    height: 20px;
    margin-bottom: 0px;
    background-color: #5cb85b33;
    border: 1px solid #5cb85b;

    mdb-bar div {
      height: 20px;
    }
  }
}

.jobs-professional-edit .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 170px !important;
}

.time-tractor {
  mdb-time-picker .md-form {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 36px;
  }
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: block;
  // opacity: 1;
}

.feedback_modal_fix_width {
  max-width: 900px;
}

.feedback_modal_fix_width {
  max-width: 900px;
}

.feedback_modal_width {
  max-width: 900px;
}

.jainAppPersonalMobile ng-select.ng-select .ng-select-container.ng-has-value {
  background-color: #dde1e6;
  border: 2px solid #dde1e6;
}

.jainAppPersonalData ng-select.ng-select.is-invalid .ng-select-container {
  border-color: #ff3547 !important;
}

@media only screen and (max-width: 1200px) {
  .feedback_modal_width {
    max-width: 700px;
  }
}

@media (min-width: 1281px) {
  .card-wrapper {
    height: 724px !important;
  }
}

///----- Jain Application ---///
@media (min-width: 1400px) and (max-width: 1600px) {
  .jainAppPersonalData ng-select {
    ng-dropdown-panel.ng-dropdown-panel {

      // min-width: 400px;
      div.ng-option {
        font-size: 12px;
        border-top: 1px solid #bcbcbc;

        span.ng-option-label {
          white-space: break-spaces !important;
        }
      }
    }
  }
}

.jainAppPersonalData ng-select {
  ng-dropdown-panel.ng-dropdown-panel {

    // min-width: 400px;
    div.ng-option {
      font-size: 12px;
      border-top: 1px solid #bcbcbc;

      span.ng-option-label {
        white-space: break-spaces !important;
      }
    }
  }
}

.classRoom-session-Tabs .col-md-12 {
  padding: 0px !important;
}

// body.modal-open {
//   position: fixed !important;
// }
.iimi-videomodel .plyr__progress input[type="range"] {
  pointer-events: none;
}

/// New Style Begins ///
/// For Forum Begins ///
.new-forum-filter-style {
  mdb-checkbox .custom-checkbox label.custom-control-label::before {
    width: 0.8rem;
    height: 0.8rem;
    left: -1.4rem;
    top: 0.38rem;
  }
}
.filter-category{
  mdb-checkbox .form-check label::before {
  top: 2px !important;
  left: 3px !important;
  width: 10px !important;
  height: 1rem !important;
  }

}

/// For Forum Ends ////
.customBold{ font-family:var(--san_francisco_bold)}
.helpdeskContainer {
  button.btn-light { background-color: #cfcccc!important; }
  button.btn-primary { background-color: #0c376f !important;}
}
.customTabProfileEdit{
  li:first-child {margin-right: 20px;}
  li,li a{border-radius: 20px 20px 0px 0px !important; background: rgba(154, 200, 253, 0.2392156863);}
  li a{color:#000 !important}
  li.active { background: #f5f1e5 !important; border:0px !important};
  li.active a.active{color: #000 !important;background: #f5f1e5 !important; border-bottom: 0px !important;}
  li a:not(.active){margin-bottom: 0px !important;}
}
.classic-tabs ul.nav.classic-tabs.customTabProfileEdit li a:not(.active){margin-bottom: 0px !important;}
.imgCropCardProfileEdit{ border-radius: 0px 10px 10px 10px !important;}
.border{ border-color:#b6b9bd !important }
.custNewBgColor{background-color: #fcf6eac4;}
.custNewBorderRadius{border-radius: 10px !important;}

// Miles Styles 
.miles-btn {   
  background: #06345B;
    width: 29%;
    margin: auto;
    padding: 10px;
    border-radius: 5px !important;
    border: none;
    font-family: var(--san_francisco_bold);
    font-size: 13px;
    color: var(--white);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.miles-card{
  background-color: #fcf6eac4 !important;
  // border-radius: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 15px 10px 15px 25px;
  position: relative;
  border : 1px solid #b6b9bd;
  
}

// .card{
//   background-color: #ffffff70 !important;
// }

.miles-heading{
  background-color: #faead1e2;
    padding: 0.8rem;
    border-radius: 10px 10px 0 0;
}

.miles-heading > h4 {
  font-weight: 900;
  margin: 0;
}
.dream_box_header{
  background: rgba(32, 67, 186, 0.1882352941);
  padding: 15px 25px 15px 25px;
  border-radius: 10px 10px 0px 0px;
  font-family: var(--san_francisco_bold);
  font-size: 1rem;
  font-weight: 600;
  color: #2043ba;
}
.tutorialTabs li:last-child{margin-left: 20px;}
.tutorialTabs li:first-child{margin-right: 0px;}
.tutorialTabs li{
  span span{font-size: 16px !important;}
  i{font-size: 1.3rem !important;font-weight: 600;}
  .active{
    span span{
    color: rgb(0, 68, 186) !important;
    border-bottom: 3px solid #0044ba;}
  }
}
.top_header{
  display: flex;
    justify-content: space-between;
    font-size: 1.5rem !important;
    background: #faead1;
    border-radius: 10px 10px 0px 0px;
    padding: 0.6rem 1rem;
    // font-family: var(--san_francisco_light) !important;
}
.text-miles{
  color : #06345B !important
}
.bg-miles{
  background-color : #06345B !important
}
.btn-miles{
  background-color: #06345B!important;
  color: #fff !important;
}
.tab_7 .card.tab-content{
	box-shadow: none;
	border: none;
	background-color: transparent !important;
}

.heading-box{
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 19px;
  display: flex;
  align-items: center;
  font-family: var(--san_francisco_reg);
  color: #000000;
  font-size: 18px;
}
// AUD
.text-aud {color: #64BC44;} 
.bg-aud {background-color: #64BC44;} 
.border-aud {border: 1px solid #64BC44;} 
// BEC
.text-bec {color: #edbf6f;} 
.bg-bec {background-color: #edbf6f;} 
.border-bec {border: 1px solid #edbf6f;} 
// FAR
.text-far {color: #527a9a;} 
.bg-far {background-color: #527a9a;} 
.border-far {border: 1px solid #527a9a;} 
// REG
.text-reg {color: #d4494c;} 
.bg-reg {background-color: #d4494c;} 
.border-reg {border: 1px solid #d4494c;} 
.pagination_box{ border-radius: 15px; background: #e2dfdf; margin-bottom: 20px; padding: 19px; display: flex; align-items: center; font-family: var(--san_francisco_reg); color: #000000; font-size: 18px;}
// .tutorialTabs{padding: 0.5rem !important;}
// .tutorialTabs >.tab-content{padding:1.5rem 1rem 1.5rem !important}


.tabs { display: flex; width: 100%;}
.tabs label { width: fit-content; display: flex; align-items: center; justify-content: center; padding: 1rem 3rem; margin-bottom: 0px; margin-right: 0.25rem; cursor: pointer; color: #212529 !important; font-size: 1.2rem; font-weight: 500; border-top: 4px solid #9ac8fd00; font-size: 16px; margin-right: 10px; border-radius: 20px 20px 0px 0px;}
.tabs label:hover {box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;}
.tabs label span i {font-size: 1.3rem; font-weight: 600;}
.tabs label .material-icons { margin-right: 0.3rem;}
.tabs .tab { flex-grow: 1; width: 100%; height: 100%; display: none; padding: 2rem 2rem; color: #000; background-color: #fff; box-shadow: 0 5px 8px rgb(0 0 0 / 7%); transition: all 0.25s;  border-bottom: 0.25rem solid #bdc3c7;}
.tabs .tab > *:not(:last-child) { margin-bottom: 0.8rem;}
.tabs label{background-color: #9ac8fd3d;}
.active-tab-1 , .active-tab-2 {background-color:#f5f1e5  !important;}
.tabs label:hover, .tabs label:hover{opacity: 0.7;}
.active-tab , .active-content{box-shadow: -1px 0px 9px #cdc9c9;}
.newButtonStyle { background-color: #0c376f !important; text-transform: none;font-size: .8rem;font-weight: 600;}
.tab-active{
  background-color: #f5f1e5 !important;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.07);
  pointer-events: none;
  font-size: 16px;
  .webiner-tab-title{
    color: #0044ba;
    border-bottom: 3px solid #0044ba;
  }
}
/// New Style Ends ///
// .modal-open .modal{ 
//   z-index: 1056;
//   // margin-top: 60px;
// }

// .spread-modal-content {
//   position: absolute;
//   top: 0%;
//   left: 50%;
//   transform: translate(-50%, 0%);
// }

.spread-modal-content.modal-dialog{
  width: 100%;
  height: 100%;
}
@media (min-width: 576px){
  .spread-modal-content.modal-dialog{
    width: 100%;
    height: 100%;
    margin: 0;
  }

}

.plyr__volume input[type=range]{
  min-width : 40px;
}
/// New Style Ends ///
.cell-top {
  cursor: move;
 }
 .onDrag {
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */
 }
 .swal2-confirm.swal2-styled {
  background-color: #06345b !important;
}